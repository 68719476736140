/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import StepTwo from "./StepTwo";

function ValidationMethod({ deadline, setDealData, setIndicator }) {
  const [deadlineSelected, setDeadlineSelected] = useState(false);
  return (
    <div className="mx-10 my-7">
      <p className="text-3xl font-bold text-[#ffffff] mb-6">
        Validation Method
      </p>
      {/* ------------ Table of Funding Wallet ------------ */}
      <div className="border-[1px] border-solid border-[#00e2ff] rounded-lg m-2 p-10">
        <div className="flex items-start justify-between">
          <div className="flex flex-col">
            <select
              name="validationType"
              id="validationType"
              className="px-4 w-60 h-10 mt-3 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] text-slate-400 text-xs text-center"
              // value={dealData.blockchain}
              // onChange={handleInputChange}
            >
              <option value="partners_signature">Partners Signature</option>
            </select>
          </div>
          <div className="mt-2 ml-7 italic">
            <p className="">
              Select the validation method to initiate your deal&apos;s payment.
            </p>
            <p>
              The deal&apos;s payment will trigger as soon as all partners have
            </p>
            <p> validated the service.</p>
          </div>
        </div>

        {/* ------------------- Deadline  ---------------- */}
        <div className="flex mt-10">
          <p className="font-bold mr-2">Set Validation Deadline </p>
          <p className="italic">
            At this date, the funds will be unlocked for the funding wallet.
          </p>
        </div>

        {/* --------- Calender ----------- */}
        <div className="flex flex-col">
          <button
            type="button"
            className="px-4 w-50 h-10 mt-3 w-[200px] border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] text-slate-400 text-xs text-center flex items-center justify-center"
            onClick={() => setDeadlineSelected(!deadlineSelected)}
          >
            {deadline ? deadline.toLocaleDateString("fr-FR") : ".../.../..."}
          </button>
          {deadlineSelected && (
            <StepTwo deadline={deadline} setDealData={setDealData} />
          )}
        </div>
      </div>

      {/* Buttons Back && Next */}
      <div className="flex justify-around my-5">
        <button
          className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full"
          type="button"
          onClick={() => setIndicator((prev) => prev - 1)}
        >
          Back
        </button>
        <button
          className={`w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full ${
            !deadline && "opacity-50"
          }`}
          type="button"
          onClick={() => {
            if (deadline) {
              setIndicator((prev) => prev + 1);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ValidationMethod;
