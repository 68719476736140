const Contract = {};

Contract.ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "feeWallet",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
    ],
    name: "AddressEmptyCode",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "AddressInsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
    ],
    name: "CancelDeal",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_erc20",
        type: "address",
      },
      {
        internalType: "string",
        name: "_creatormail",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_creatorNetAmount",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_mailoffundingWallet",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_grossAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_netAmount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_mediator",
        type: "address",
      },
      {
        components: [
          {
            internalType: "string",
            name: "mail",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct DShare.DealPartner[]",
        name: "_DealPartner",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "nbhours",
        type: "uint256",
      },
    ],
    name: "CreateDeal",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "FailedInnerCall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "OwnableInvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error",
  },
  {
    inputs: [],
    name: "ReentrancyGuardReentrantCall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "SafeERC20FailedOperation",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
    ],
    name: "DealApproved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
    ],
    name: "DealCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "Creator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
    ],
    name: "DealCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "fundingWallet",
        type: "address",
      },
    ],
    name: "DealFunded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
    ],
    name: "DealValidated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "Caller",
        type: "address",
      },
    ],
    name: "Dealmediated",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        internalType: "enum DShare.DealState",
        name: "state",
        type: "uint8",
      },
    ],
    name: "MediatorDealResponse",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        internalType: "enum DShare.DealState",
        name: "state",
        type: "uint8",
      },
      {
        internalType: "string",
        name: "mailofpartner",
        type: "string",
      },
    ],
    name: "PatnerDealResponse",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        internalType: "enum DShare.ServiceState",
        name: "state",
        type: "uint8",
      },
    ],
    name: "PatnerDealValidation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "NewFeeWallet",
        type: "address",
      },
    ],
    name: "SetFeeWallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "WalletFee",
        type: "address",
      },
    ],
    name: "SetWalletFee",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
    ],
    name: "UnlockFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "FundingWallet",
        type: "address",
      },
    ],
    name: "UnlockFunds",
    type: "event",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
  {
    inputs: [],
    name: "FeeWallet",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "dealId",
        type: "uint256",
      },
    ],
    name: "GetDealDetails",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "currencyContract",
            type: "address",
          },
          {
            components: [
              {
                internalType: "address",
                name: "creator",
                type: "address",
              },
              {
                internalType: "string",
                name: "mailofdealcreator",
                type: "string",
              },
              {
                internalType: "uint256",
                name: "creatorNetAmount",
                type: "uint256",
              },
              {
                internalType: "enum DShare.ServiceState",
                name: "_ServiceState",
                type: "uint8",
              },
            ],
            internalType: "struct DShare.CreatorDetails",
            name: "Creator",
            type: "tuple",
          },
          {
            internalType: "string",
            name: "mailoffundingWallet",
            type: "string",
          },
          {
            internalType: "address",
            name: "addressoffundingWallet",
            type: "address",
          },
          {
            components: [
              {
                internalType: "address",
                name: "Mediator",
                type: "address",
              },
              {
                internalType: "enum DShare.DealState",
                name: "_DealState",
                type: "uint8",
              },
            ],
            internalType: "struct DShare.MediatorDetails",
            name: "mediator",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "string",
                name: "mail",
                type: "string",
              },
              {
                internalType: "address",
                name: "addPartner",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "netAmount",
                type: "uint256",
              },
              {
                internalType: "enum DShare.DealState",
                name: "_DealState",
                type: "uint8",
              },
              {
                internalType: "enum DShare.ServiceState",
                name: "_ServiceState",
                type: "uint8",
              },
              {
                internalType: "bool",
                name: "Is_It_A_Funding_Wallet",
                type: "bool",
              },
            ],
            internalType: "struct DShare.PartnerDetails[]",
            name: "partners",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "partnerCount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ValidatorCount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "grossAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "netAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "protocolFee",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "deadline",
            type: "uint256",
          },
          {
            internalType: "enum DShare.GlobalDealState",
            name: "_GDealstate",
            type: "uint8",
          },
          {
            internalType: "enum DShare.GlobalServiceState",
            name: "_GServiceState",
            type: "uint8",
          },
          {
            internalType: "enum DShare.FundsState",
            name: "_FundState",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "NbApprovedDeal",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "NbApprovedService",
            type: "uint256",
          },
        ],
        internalType: "struct DShare.Deal",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export default Contract;
