/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import axios from "axios";
import { useAccount } from "wagmi";
// import close from "../../../assets/icons/Icon ionic-md-close.svg";
import Indicators from "./Indicators";
import StepOne from "./StepOne";
// import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import { dealApi } from "../../../api/api";
import ContractERC20 from "../../../contracts/erc20/ABI_ERC20";
import ContractDshare from "../../../contracts/dshare/ABI_DShare";
import SuccessModal from "../../modals/SuccessModal";
import FailureModal from "../../modals/FailureModal";
import { clearEmailConnected } from "../../../store/features/walletSlice/walletSlice";
import { clearMediator } from "../../../store/features/mediatorSlice/mediatorSlice";
import ValidationMethod from "./ValidationMethod";
import Review from "./Review";
import FundingModal from "../../modals/FundingModal";
import NotFundingModal from "../../modals/NotFundingModal";
import { getAndConvertBalance, getTokenDecimals } from "../../../utils/utils";

const tokenAddress = process.env.REACT_APP_ERC20;
const dshareAddress = process.env.REACT_APP_DSHARE_SC;
const web3 = new Web3(window.ethereum);
const TokenSC = new web3.eth.Contract(ContractERC20.ABI, tokenAddress);
const DshareSC = new web3.eth.Contract(ContractDshare.ABI, dshareAddress);
const dealDeadline = new Date();
dealDeadline.setHours(23, 59);
const initialState = {
  creatorWallet: "",
  creatorEmail: "",
  dealName: "",
  dealAmount: "",
  blockchain: "",
  currency: "",
  fees: true,
  fees_amount: "",
  grossAmount: "",
  netAmount: "",
  deadline: dealDeadline,
  creator: {},
  partners: [],
  mediator: {},
  fundingWallet: "",
};

function NewDealForm() {
  // indicator state
  const [indicator, setIndicator] = useState(0);
  const [dealData, setDealData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailureModal, setOpenFailureModal] = useState(false);
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [createdDealId, setCreatedDealId] = useState();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const emailConnected = useSelector((state) => state.user.emailConnected);
  const { address } = useAccount();
  const wallet = address?.toLowerCase(); // useSelector((state) => state.user.walletAddress);
  const isMediator = useSelector((state) => state.mediator);
  // Clear emailConnected when partner want to create new deal
  useEffect(() => {
    if (emailConnected) {
      dispatch(clearEmailConnected());
    }
  }, []);

  // Change mediator to false when mediator want to create new deal
  useEffect(() => {
    if (isMediator) {
      dispatch(clearMediator());
    }
  }, []);
  useEffect(() => {
    setDealData((prevDealData) => ({
      ...prevDealData,
      creatorWallet: wallet,
    }));
  }, [wallet]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "dealAmount") {
      if (value.length <= 6) setDealData({ ...dealData, [name]: value });
    } else if (name === "creatorEmail") {
      // Convert the value to lowercase before saving it
      setDealData({ ...dealData, [name]: value.toLowerCase() });
    } else setDealData({ ...dealData, [name]: value });
  };
  const getHoursDiff = (deadline) => {
    // Get the current date
    const today = new Date();
    // Create the target date
    const targetDate = new Date(deadline);
    // Calculate the time difference in milliseconds
    const timeDiff = targetDate.getTime() - today.getTime();
    // Convert the time difference to hours
    const hoursDiff = Math.ceil(timeDiff / (1000 * 60 * 60));
    return hoursDiff;
  };
  const createDealBc = () => {
    return new Promise((resolve) => {
      const {
        creator,
        creatorEmail,
        fundingWallet,
        grossAmount,
        netAmount,
        deadline,
        mediator,
        partners,
      } = dealData;

      // Set Mediator data
      let mediatorWallet;

      if (Object.keys(mediator).length === 0) {
        mediatorWallet = "0x0000000000000000000000000000000000000000";
      } else {
        mediatorWallet = mediator.wallet;
      }

      // Get decimals
      TokenSC.methods
        .decimals()
        .call()
        .then((decimals) => {
          console.log(decimals);
          // Set partners array
          const partnersArray = partners.map(({ email, netAmount: net }) => [
            email,
            dealData.fundingWallet === email
              ? 0
              : Number((net * 10 ** Number(decimals)).toFixed(2)), // Convert back to number after rounding
          ]);

          const grossAmountWei = Number(
            (grossAmount * 10 ** Number(decimals)).toFixed(2)
          );
          const netAmountWei = Number(
            (netAmount * 10 ** Number(decimals)).toFixed(2)
          );
          const feeAmount = Number(
            (dealData.fees_amount * 10 ** Number(decimals)).toFixed(2)
          );

          const creatorNetAmountInWei =
            dealData.fundingWallet === dealData.creatorEmail
              ? 0
              : Number(
                  (creator.creatorNetAmount * 10 ** Number(decimals)).toFixed(2)
                );

          // Get the difference between now and deadline
          const nbHours = getHoursDiff(deadline);

          // Call create deal method from the smart contract
          const createDealTx = DshareSC.methods.CreateDeal(
            tokenAddress,
            creatorEmail,
            creatorNetAmountInWei,
            fundingWallet,
            grossAmountWei,
            feeAmount,
            netAmountWei,
            mediatorWallet,
            partnersArray,
            nbHours
          );

          let backResponseResult;
          let transactionHash;

          createDealTx
            .send({
              from: dealData.creatorWallet,
            })
            .on("transactionHash", async (hash) => {
              transactionHash = hash;

              // Make the API call and wait for the response
              backResponseResult = await dealApi.createDeal(dealData, hash);
              const etherScanApiKey = process.env.REACT_APP_SCAN_API_KEY;

              // Validate transactionHash here (check for its format, length, etc)
              if (!hash) {
                throw new Error("Invalid transaction hash");
              }

              let responseData;

              // Define a function to make the API call
              const makeAPICall = async () => {
                const response = await axios.get(
                  `https://api.polygonscan.com/api?module=proxy&action=eth_getTransactionReceipt&txhash=${hash}&apikey=${etherScanApiKey}`
                );

                if (response.data.error) {
                  throw new Error(response.data.error.message);
                }

                // Extract the relevant data from the response
                responseData = response.data;
              };

              // Set up an interval to check the API every 5 seconds
              const intervalId = setInterval(async () => {
                await makeAPICall();

                // Check if the desired condition is met (modify this condition as needed)
                if (
                  responseData.result &&
                  responseData.result.status === "0x1" &&
                  backResponseResult.status === 201
                ) {
                  // Handle the data as needed and return it
                  clearInterval(intervalId);
                  resolve({ backResponseResult, transactionHash });
                }
              }, 5000);
            })
            .catch((error) => {
              if (!error.message.includes("not mined within")) {
                setIsLoading(false);
                setOpenFailureModal(true);
              }

              console.error("Error in createDealBc:", error);
              // reject(error);
            });
        })
        .catch((error) => {
          console.error("Error getting decimals:", error);
          // reject(error);
        });
    });
  };

  // approve the smart contract to spend the deal amount
  const approve = async () => {
    try {
      const decimals = await TokenSC.methods.decimals().call();
      const grossAmountWei = Number(
        (dealData.grossAmount * 10 ** Number(decimals)).toFixed(2)
      );
      const approveTx = TokenSC.methods.approve(dshareAddress, grossAmountWei);
      // Send the transaction and open MetaMask window for signing
      await approveTx
        .send({
          from: dealData.creatorWallet,
        })
        .on("transactionHash", async (hash) => {
          const etherScanApiKey = process.env.REACT_APP_SCAN_API_KEY;
          // Validate transactionHash here (check for its format, length, etc)
          if (!hash) {
            throw new Error("Invalid transaction hash");
          }

          let responseData;

          // Define a function to make the API call
          const makeAPICall = async () => {
            const response = await axios.get(
              `https://api.polygonscan.com/api?module=proxy&action=eth_getTransactionReceipt&txhash=${hash}&apikey=${etherScanApiKey}`
            );

            if (response.data.error) {
              throw new Error(response.data.error.message);
            }

            // Extract the relevant data from the response
            responseData = response.data;
          };

          // Set up an interval to check the API every 5 seconds
          const intervalId = setInterval(async () => {
            await makeAPICall();

            // Check if the desired condition is met (modify this condition as needed)
            if (responseData.result && responseData.result.status === "0x1") {
              // Handle the data as needed and return it
              clearInterval(intervalId); // Stop the interval
              const createDealRes = await createDealBc();
              if (
                createDealRes.backResponseResult &&
                createDealRes.backResponseResult.status === 201 &&
                createDealRes.transactionHash
              ) {
                setCreatedDealId(
                  createDealRes.backResponseResult.data.deal._id
                );
                setOpenSuccessModal(true);
              } else {
                setOpenFailureModal(true);
              }

              setIsLoading(false);
              return responseData;
            }
          }, 5000);
        });
    } catch (error) {
      console.log(error);
      if (!error.message.includes("not mined within")) {
        setIsLoading(false);
        setOpenFailureModal(true);
        throw error;
      } else {
        console.log("Timing Error ", error.message);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (dealData.creatorEmail === dealData.fundingWallet) {
        const balanceInEther = await getAndConvertBalance(
          dealData.creatorWallet,
          TokenSC
        );

        if (dealData.grossAmount > balanceInEther) {
          setInsufficientFunds(true);
          return;
        }
        setIsLoading(true);
        await approve();
      } else {
        setIsLoading(true);
        const createDealRes = await createDealBc();
        if (
          createDealRes.backResponseResult.status === 201 &&
          createDealRes.transactionHash
        ) {
          setCreatedDealId(createDealRes.backResponseResult.data.deal._id);
          setOpenSuccessModal(true);
        } else {
          setOpenFailureModal(true);
        }

        setIsLoading(false);
      }

      return null;
    } catch (error) {
      if (!error.message.includes("not mined within")) {
        setIsLoading(false);
        setOpenFailureModal(true);
      }
    }
  };

  const closeSuccessModal = () => {
    navigate(`/deal/${createdDealId}`);
  };
  const closeFailureModal = () => {
    setOpenFailureModal(false);
    setInsufficientFunds(false);
  };
  const goToDeal = () => {
    navigate(`/deal/${createdDealId}`);
  };

  return (
    <div>
      <Indicators indicator={indicator} />
      <div className="flex justify-center">
        <form className="text-white flex flex-col items-stretch  bg-gradient-to-t from-deep-navy to-steel-blue filter drop-shadow-md  border-[5px] border-solid border-[#00e2ff] rounded-lg">
          {/* Form Title */}
          {/* <div className="flex flex-row items-center justify-between m-4">
          <legend className="text-2xl font-bold text-[#ffffff] ">
            New Deal
          </legend>
          {indicator === 0 ? (
            <div className="mr-20">
              <Indicators indicator={indicator} />
            </div>
          ) : (
            <Indicators indicator={indicator} />
          )}
          <div className="flex">
            {indicator > 0 && (
              <div className="mr-4">
                <b className="text-xl">{dealData.grossAmount}</b>
                <span className="text-xs">{dealData.currency}</span>
              </div>
            )}
            <button type="button" onClick={() => navigate("/dashboard")}>
              <img src={close} alt="close" className="w-4" />
            </button>
          </div>
        </div> */}

          {/* <hr className=" border-[1px] border-solid border-[#00e2ff] opacity-50" /> */}
          {indicator === 0 && (
            <StepOne
              dealData={dealData}
              setDealData={setDealData}
              handleInputChange={handleInputChange}
              setIndicator={setIndicator}
            />
          )}
          {indicator === 1 && (
            // <StepTwo
            //   deadline={dealData.deadline}
            //   setDealData={setDealData}
            //   setIndicator={setIndicator}
            // />

            <StepThree
              dealData={dealData}
              setDealData={setDealData}
              setIndicator={setIndicator}
            />
          )}
          {indicator === 2 && (
            // <StepTwo
            //   deadline={dealData.deadline}
            //   setDealData={setDealData}
            //   setIndicator={setIndicator}
            // />
            <ValidationMethod
              deadline={dealData.deadline}
              setDealData={setDealData}
              setIndicator={setIndicator}
            />
          )}
          {indicator === 3 && (
            <StepFour
              dealData={dealData}
              setDealData={setDealData}
              // isLoading={isLoading}
              setIndicator={setIndicator}
              // handleSubmit={handleSubmit}
            />
          )}
          {indicator === 4 && (
            <Review
              dealData={dealData}
              isLoading={isLoading}
              setIndicator={setIndicator}
              handleSubmit={handleSubmit}
            />
          )}
        </form>
        {openSuccessModal && (
          <SuccessModal
            title="Deal created successfully !"
            text=" Congratulations. Your deal has been created successfully"
            closeModal={closeSuccessModal}
            onButtonClick={goToDeal}
          />
        )}
        {openFailureModal && (
          <FailureModal
            title="Oops !"
            text=" An error has occurred while creating the deal"
            closeModal={closeFailureModal}
          />
        )}

        {isLoading ? (
          dealData?.fundingWallet === dealData?.creatorEmail ? (
            <FundingModal />
          ) : (
            <NotFundingModal />
          )
        ) : null}

        {insufficientFunds && (
          <FailureModal
            title="Insufficient Funds !"
            text=" you don't have enough funds to create the deal "
            closeModal={closeFailureModal}
          />
        )}
      </div>
    </div>
  );
}

export default NewDealForm;
