import React from "react";
import iImage from "../../assets/images/letter-i.png";
import attention from "../../assets/images/attention.png";

function NotFundingModal() {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-eclipse-blue w-[70%] max-w-[600px] md:max-w-[30%] rounded shadow px-6 py-8 border-4 border-ice-blue text-white space-y-4">
        <h2 className="text-2xl font-bold mb-2 text-center mt-2">
          Please sign your transaction in your browser&rsquo;s wallet
        </h2>
        <div className="flex flex-col">
          <div className="flex mb-2">
            <img className="w-6 h-6 mr-2" src={iImage} alt="i icon" />
            <p className="">
              It may take more than one minute in case of network congestion.
              Follow the status of your transaction through your blockchain
              explorer.
            </p>
          </div>

          <div className="flex mb-2">
            <img className="w-6 h-6 mr-2" src={attention} alt="alert icon" />
            <p className="">
              IMPORTANT: Do not accelerate your transaction through your
              browser&rsquo;s wallet, or the deal will not be updated properly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFundingModal;
