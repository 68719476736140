/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../../LoadingSpinner";

function Review({ dealData, setIndicator, handleSubmit, isLoading }) {
  // Find wallet address (change the key names in the data when he is the creator to match those of the partner)
  const fundingWalletData =
    dealData.creatorEmail.toLowerCase() === dealData.fundingWallet.toLowerCase()
      ? {
          sharePercentage: dealData.creator.creatorShare,
          grossAmount: dealData.creator.creatorGrossAmount,
          feeAmount: dealData.creator.creatorFeeAmount,
          netAmount: dealData.creator.creatorNetAmount,
        }
      : dealData.partners.find(
          (partner) => partner.email === dealData?.fundingWallet
        );
  return (
    <div className="mx-10 my-7">
      {/* Title */}
      <p className="text-center text-3xl font-bold mb-4">Review & Deploy</p>
      <hr className="border-[#00e2ff] border-solid border-t-1 my-4" />
      <p className="text-3xl font-bold text-[#ffffff] mb-6">Deal Information</p>
      {/* Your creatorEmail */}
      <div className="flex flex-col ">
        <p
          htmlFor="creatorEmail"
          className="text-base font-bold text-[#f3f8fe]"
        >
          Your Email Address
        </p>
        <p className="px-4 w-full h-10 mt-3 flex items-center justify-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center">
          {dealData.creatorEmail}
        </p>
      </div>

      {/* Deal name */}
      <div className="flex flex-col mt-2">
        <p
          htmlFor="creatorEmail"
          className="text-base font-bold text-[#f3f8fe]"
        >
          Deal Name
        </p>
        <p className="px-4 w-full h-10 mt-3 flex items-center justify-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center">
          {dealData.dealName}
        </p>
      </div>

      {/* Deal Amount */}
      <div className="flex flex-col mt-2">
        <span className="text-base font-bold text-[#f3f8fe]">
          Deal Total Amount
        </span>
        <div className="w-full flex justify-between flex-row-reverse">
          {/* Currency */}

          <p className="px-4 w-[200px] h-10 mt-3 font-bold flex items-center justify-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue  text-xs text-center">
            {dealData.currency}
          </p>

          {/* Blockchain type */}

          <p className="px-4 w-[260px] h-10 mt-3 font-bold flex items-center justify-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue  text-xs text-center">
            {dealData.blockchain}
          </p>

          {/* Deal Amount */}

          <p className="px-4 w-[700px] h-10 mt-3 font-bold flex items-center justify-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center">
            {dealData.dealAmount}
          </p>
        </div>

        {/* Protocol Fees Allocation */}

        <div className="flex flex-col mt-2">
          <p
            htmlFor="dealAmount"
            className="text-base font-bold text-[#f3f8fe] mb-2"
          >
            Protocol Fees Allocation
          </p>
        </div>
        <div className="">
          {/* Protocol Fee */}
          <p className="text-sm text-[#f3f8fe]">
            {dealData.fees ? "Paid By Client" : "Paid By Provider(s)"}
          </p>
          {/* Deal Amount Fee incl */}
          <div className="text-sm text-[#f3f8fe] flex">
            <p className="mr-2">Deal Amount Fee incl.:</p>
            <p className="font-bold">
              {`${
                dealData.dealAmount &&
                (dealData.fees || !dealData.fees) &&
                dealData.grossAmount
              } USDT`}
            </p>
          </div>
          {/*  Paid by Client */}
          <div className="text-sm text-[#f3f8fe] flex">
            <p className="mr-2"> Paid by Client:</p>
            <p className="font-bold">
              {`${
                dealData.dealAmount &&
                (dealData.fees || !dealData.fees) &&
                (dealData.fees ? dealData.fees_amount : "0")
              } USDT`}
            </p>
          </div>
          {/*  Paid by Provider(s) */}
          <div className="text-sm text-[#f3f8fe] flex">
            <p className="mr-2">Paid by Provider(s):</p>
            <p className="font-bold">
              {`${
                dealData.dealAmount &&
                (dealData.fees || !dealData.fees) &&
                (dealData.fees ? "0" : dealData.fees_amount)
              } USDT`}
            </p>
          </div>
        </div>
      </div>

      {/* ------------------- Deal Parameters --------------------- */}
      <p className="text-3xl font-bold text-[#ffffff] mb-6 mt-10">
        Deal Parameters
      </p>

      <div className="m-2 p-2">
        <p className="text-xl font-bold text-[#ffffff] mb-1">From:</p>

        {/* Funding Wallet data */}
        {dealData?.fundingWallet && (
          <div>
            <div className="flex flex-row justify-around text-xs">
              <p className="w-[400px]">{}</p>
              <p className="w-[100px] ml-8">Share to pay</p>
              <p className="w-[120px] ml-8">Gross amount</p>
              <p className="w-[100px] ml-2">Fee amount</p>
              <p className="w-[196px] ml-4">Net amount to pay</p>
            </div>
            <div className="bg-steel-blue mx-5 w-[1160px]">
              <div
                // key={index}
                className="h-16 flex flex-row justify-around bg-steel-blue mt-2"
              >
                <p className="w-[400px] flex items-center justify-center">
                  {dealData?.fundingWallet}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {`${fundingWalletData?.sharePercentage}%`}
                </p>
                <p className="w-[120px] flex items-center justify-center font-bold">
                  {fundingWalletData?.grossAmount}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {fundingWalletData?.feeAmount}
                </p>
                <div className="w-[196px] pr-6 flex items-center justify-around font-bold">
                  <p>{fundingWalletData?.netAmount}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ------------------- Providers data ---------------- */}
        <p className="text-xl font-bold text-[#ffffff] mt-4 ">To:</p>
        <div className="flex flex-row justify-around text-xs mb-2">
          <p className="w-[400px] ">{}</p>
          <p className="w-[100px] pl-2">Share to receive</p>
          <p className="w-[120px] pl-6">Gross amount</p>
          <p className="w-[100px] pl-2">Fee amount</p>
          <p className="w-[196px] pl-4">Net amount to receive</p>
        </div>
        {/* ------- creator Data if is not funding wallet ---------- */}
        <div className="bg-steel-blue mx-5 w-[1160px]">
          {Object.keys(dealData.creator).length !== 0 &&
            dealData.creatorEmail.toLowerCase() !==
              dealData.fundingWallet.toLowerCase() && (
              <div className="h-16 flex flex-row justify-around bg-steel-blue">
                <p className="w-[400px] flex items-center justify-center">
                  {dealData.creatorEmail}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {`${dealData.creator.creatorShare}%`}
                </p>
                <p className="w-[120px] flex items-center justify-center font-bold">
                  {dealData.creator.creatorGrossAmount}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {dealData.creator.creatorFeeAmount}
                </p>
                <div className="w-[196px] flex items-center justify-around font-bold">
                  <p>{dealData.creator.creatorNetAmount}</p>
                </div>
              </div>
            )}
        </div>
        {/*  -------------- Partners data ------------------ */}
        <div className="bg-steel-blue mx-5 w-[1160px]">
          {dealData.partners
            .map((partner, index) => ({ ...partner, index })) // Ajouter l'index original à chaque partenaire
            .filter((partner) => partner.email !== dealData.fundingWallet)
            .map((partner) => (
              <div
                key={partner.index}
                className={`h-16 flex flex-row justify-around bg-steel-blue ${
                  partner.email === dealData.fundingWallet
                    ? "border-2 border-ice-blue"
                    : ""
                }`}
              >
                <p className="w-[400px] flex items-center justify-center">
                  {partner.email}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {`${partner.sharePercentage}%`}
                </p>
                <p className="w-[120px] flex items-center justify-center font-bold">
                  {partner.grossAmount}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {partner.feeAmount}
                </p>
                <div className="w-[196px] flex items-center justify-around font-bold">
                  <p>{partner.netAmount}</p>
                </div>
              </div>
            ))}
        </div>

        {/* ------------------- Validation Method --------------------- */}
        <p className="text-3xl font-bold text-[#ffffff] mb-6 mt-10">
          Validation Method
        </p>
        <div className="px-4 w-[200px] h-10 mt-3 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue text-[#ffffff] text-xs  flex items-center justify-center">
          Partners Signature
        </div>
        <p className="text-xl font-bold text-[#ffffff] mb-6 mt-10">
          Validation Deadline
        </p>
        <p className="px-4 w-[150px] h-10 mt-3 text-[#ffffff]  border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue text-xs text-center flex items-center justify-center">
          {dealData?.deadline?.toLocaleDateString("fr-FR")}
        </p>

        {/* ----------------------- Arbitrator (Mediator) DATA ------------------------ */}
        {Object.keys(dealData.mediator).length > 0 && (
          <div>
            <p className="text-3xl font-bold text-[#ffffff] mb-6 mt-10">
              Arbitration
            </p>
            <div className="flex flex-row justify-around text-l mb-1">
              <p className="mx-5 w-[370px] flex items-center justify-center">
                Arbitrator Email Address
              </p>
              <p className="flex items-center justify-center mr-4 w-[420px]">
                Arbitrator Wallet Address
              </p>
              <p className="pr-4 w-[250px] flex items-center justify-center">
                Commission % on net amount
              </p>
              <p className="mr-9 w-[140px] flex items-center justify-center">
                Net amount
              </p>
            </div>

            <div className="flex justify-between mb-5 mx-5">
              {/* Mediator Email address */}
              <div className="flex flex-col">
                <input
                  className="text-center mr-4 px-4 w-[370px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  value={dealData.mediator.email}
                />
              </div>
              {/* Mediator wallet address */}
              <div className="flex flex-col">
                <input
                  className="text-center mr-4 px-4 w-[420px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  value={dealData.mediator.wallet}
                />
              </div>
              {/* Commission percentage */}
              <div className="flex flex-col">
                <input
                  className="text-center mr-4 px-4 w-[220px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="commissionPercentage"
                  value={dealData.mediator.commissionPercentage}
                />
              </div>
              {/* Net Amount */}
              <div className="flex flex-col">
                <input
                  className="text-center mr-4 px-4 w-[140px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="netAmount"
                  value={dealData.mediator.netAmount}
                />
              </div>
            </div>
          </div>
        )}

        {/* Buttons Back && Next */}
        <div className="w-full mt-14 flex justify-center">
          <div className="w-[407px] flex justify-around mb-5">
            <button
              className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full"
              type="button"
              onClick={() => setIndicator((prev) => prev - 1)}
            >
              Back
            </button>

            <button
              disabled={isLoading}
              className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full flex items-center justify-center"
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              <div className="text-center">
                {!isLoading ? (
                  "Deploy"
                ) : (
                  <div className="w-10 h-10">
                    {" "}
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Review.propTypes = {
  // dealData: PropTypes.shape({
  //   creatorEmail: PropTypes.string.isRequired,
  //   dealName: PropTypes.string.isRequired,
  //   dealAmount: PropTypes.string.isRequired,
  //   blockchain: PropTypes.string.isRequired,
  //   currency: PropTypes.string.isRequired,
  //   fees: PropTypes.bool.isRequired,
  //   grossAmount: PropTypes.string.isRequired,
  // }).isRequired,

  setIndicator: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Review;
