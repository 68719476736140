import axios from "axios";

// eslint-disable-next-line no-unused-vars
const userApiClientBase = () =>
  axios.create({
    baseURL: "https://www.app.d-share.io/api/v1",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const dealApi = {
  async getDeal(id) {
    try {
      const res = await userApiClientBase().get(`/deal/${id}`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async createDeal(dealData, transactionHash) {
    try {
      const res = await userApiClientBase().post("/deal", {
        ...dealData,
        transactionHash,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async updatePartnerDealStatus(
    dealId,
    partnerId,
    status,
    wallet,
    transactionHash
  ) {
    try {
      const res = await userApiClientBase().patch(
        `/deal/deals/${dealId}/partners/${partnerId}/update-status`,
        { status, wallet, transactionHash }
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async updateMediatorDealStatus(dealId, status, transactionHash) {
    try {
      const res = await userApiClientBase().patch(
        `/deal/deals/${dealId}/mediator/update-status`,
        { status, transactionHash }
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  },

  async cancelDeal(dealId, wallet, transactionHash) {
    try {
      const res = await userApiClientBase().patch(`/deal/cancel/${dealId}`, {
        wallet,
        transactionHash,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async getDealsByWallet(wallet) {
    try {
      const res = await userApiClientBase().get(`/deal/deals/${wallet}`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async archiveDeal(deal, wallet) {
    try {
      const res = await userApiClientBase().patch("/deal/archive", {
        wallet,
        deal,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async unlockFunds(deal, wallet, transactionHash) {
    try {
      const res = await userApiClientBase().patch("/deal/unlock", {
        deal,
        wallet,
        transactionHash,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async getNotificationsByWallet(wallet) {
    try {
      const res = await userApiClientBase().get(
        `/deal/notifications/${wallet}`
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};

export const partnerApi = {
  async getPartner(email) {
    try {
      const res = await userApiClientBase().get(`/partner/${email}`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async getPartnerById(id) {
    try {
      const res = await userApiClientBase().get(`/partner/id/${id}`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async getPartnerByDealId(id) {
    try {
      const res = await userApiClientBase().get(`/partner/partners/${id}`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async validatePartnerServiceStatus(dealId, partnerId, transactionHash) {
    try {
      const res = await userApiClientBase().patch(`/partner/validate-service`, {
        dealId,
        partnerId,
        transactionHash,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async rejectPartnerServiceStatus(dealId, partnerId, isLast) {
    try {
      const res = await userApiClientBase().patch(`/partner/reject-service`, {
        dealId,
        partnerId,
        isLast,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};

export const mediatorApi = {
  async callMediator(dealId, callerId, reason, transactionHash) {
    try {
      const res = await userApiClientBase().patch(
        `deal/deals/call-mediator/${dealId}`,
        { callerId, reason, transactionHash }
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};

export const creatorApi = {
  async validateCreatorServiceStatus(dealId, transactionHash) {
    try {
      const res = await userApiClientBase().patch(`/creator/validate-service`, {
        dealId,
        transactionHash,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async rejectCreatorServiceStatus(dealId, isLast) {
    try {
      const res = await userApiClientBase().patch(`/creator/reject-service`, {
        dealId,
        isLast,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  async callMediator(dealId, creatorId, reason, transactionHash) {
    try {
      const res = await userApiClientBase().patch(
        `/creator/call-mediator/${dealId}`,
        { creatorId, reason, transactionHash }
      );
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};

export const notificationApi = {
  async notificationIsRead(notificationId) {
    try {
      const res = await userApiClientBase().patch("/notification", {
        notificationId,
      });
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};

export const adminApi = {
  async sendTransaction(data) {
    try {
      const res = await userApiClientBase().post("/admin/send", {
        data,
      });

      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};

export const feeApi = {
  async getFeeValue() {
    try {
      const res = await userApiClientBase().get("config/fee");
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
};
