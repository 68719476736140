/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Calendar from "react-calendar";

import "./style.css";
import nextSvg from "../../../assets/icons/next.svg";
import prevSvg from "../../../assets/icons/prev.svg";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function ControlBtn({ id, onClick, alt, svg, className = "" }) {
  return (
    <div
      id={id}
      className={`w-8 h-6  flex justify-center items-center cursor-pointer ${className}`}
      onClick={onClick}
    >
      <img src={svg} alt={alt} />
    </div>
  );
}

// ************************************************
function StepTwo({ setDealData, deadline }) {
  const [month, setMonth] = useState(months[deadline.getMonth()]);
  const [year, setYear] = useState(deadline.getFullYear());

  const refNextMonth = useRef(null);
  const refPrevMonth = useRef(null);
  const refNextYear = useRef(null);
  const refPrevYear = useRef(null);

  const refContent = useRef(null);

  useEffect(() => {
    refNextMonth.current = document.getElementById("next_month");
    refPrevMonth.current = document.getElementById("prev_month");
    refNextYear.current = document.getElementById("next_year");
    refPrevYear.current = document.getElementById("prev_year");

    refContent.current = document.getElementsByClassName(
      "react-calendar__navigation__label__labelText--from"
    )[0];
  }, []);

  useEffect(() => {
    const updateContent = () => {
      if (refContent.current) {
        const textInfo = refContent.current.textContent;
        const [m, y] = textInfo.split(" ");
        setMonth(m);
        setYear(y);
      }
    };
    const observer = new MutationObserver(updateContent);
    observer.observe(refContent.current, {
      characterData: true,
      subtree: true,
    });

    // clean
    return () => observer.disconnect();
  }, []);

  const onChange = (val) => {
    val.setHours(23, 59);
    setDealData((prev) => {
      return { ...prev, deadline: val };
    });
  };

  return (
    <div className="w-[400px]">
      {/* <span className="mb-[10px] inline-block">Deadline</span> */}
      <div className="flex justify-end bg-deep-navy p-1">
        <ControlBtn
          onClick={() => refPrevMonth.current.click()}
          alt="prev month"
          svg={prevSvg}
        />
        <span>{month} </span>
        <ControlBtn
          onClick={() => refNextMonth.current.click()}
          alt="next month"
          svg={nextSvg}
          className="mr-4"
        />
        <ControlBtn
          onClick={() => refPrevYear.current.click()}
          alt="prev year"
          svg={prevSvg}
        />
        <span> {year} </span>
        <ControlBtn
          onClick={() => refNextYear.current.click()}
          alt="next year"
          svg={nextSvg}
        />
      </div>
      <hr className="border-[#26374C]" />
      <Calendar
        onChange={onChange}
        minDate={new Date()}
        value={deadline}
        locale="en"
        className=""
        nextLabel={<ControlBtn id="next_month" />}
        prevLabel={<ControlBtn id="prev_month" />}
        next2Label={<ControlBtn id="next_year" />}
        prev2Label={<ControlBtn id="prev_year" />}
        tileClassName={({ date, view }) =>
          view === "month" &&
          date.getDate() === deadline.getDate() &&
          date.getFullYear() === deadline.getFullYear() &&
          date.getMonth() === deadline.getMonth()
            ? "selected-date"
            : null
        }
      />
      <hr className="border-[#26374C]" />
      <div className="py-2 px-6 bg-[#192532]">
        {/* <p className="text-[11px]">
          On this date, you will get your money back in your wallet if the deal
          is not validated.
        </p> */}
      </div>
      {/* Buttons Back && Next */}
      {/* <div className="flex justify-around my-5">
        <button
          className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full"
          type="button"
          onClick={() => setIndicator((prev) => prev - 1)}
        >
          Back
        </button>
        <button
          className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full"
          type="button"
          onClick={() => {
            if (deadline !== "") {
              setIndicator((prev) => prev + 1);
            }
          }}
        >
          Next
        </button>
      </div> */}
    </div>
  );
}

export default StepTwo;
