/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react";

function TableRow({ rowData, color }) {
  return (
    <tr className={color}>
      {rowData.map((value, index) => (
        <td key={index} className="p-4">
          {value}
        </td>
      ))}
    </tr>
  );
}

export default TableRow;
