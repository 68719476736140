/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import Web3 from "web3";
import axios from "axios";
import { dealApi } from "../../api/api";
import ContractDshare from "../../contracts/dshare/ABI_DShare";
import ContractERC20 from "../../contracts/erc20/ABI_ERC20";
import LoadingSpinner from "../LoadingSpinner";
import FailureModal from "../modals/FailureModal";
import FundingModal from "../modals/FundingModal";
import NotFundingModal from "../modals/NotFundingModal";
import { getAndConvertBalance, getTokenDecimals } from "../../utils/utils";

const web3 = new Web3(window.ethereum);
const tokenAddress = process.env.REACT_APP_ERC20;
const TokenSC = new web3.eth.Contract(ContractERC20.ABI, tokenAddress);
const dshareAddress = process.env.REACT_APP_DSHARE_SC;
const DshareSC = new web3.eth.Contract(ContractDshare.ABI, dshareAddress);

function ApprovalButton({
  deal,
  partnerId,
  isMediator,
  onDealRejection,
  partnerEmail,
  isFundingWallet,
  setDealData,
  setWalletWarning,
}) {
  const { address } = useAccount();
  const walletConnected = address?.toLowerCase();
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [insufficientFunds, setInsufficientFunds] = useState(false);

  const user = useSelector((state) => state.user);

  const handleApprove = async () => {
    const dealResponse = await dealApi.getDeal(deal._id);
    const { deal_status, partners, mediator_deal_status } = dealResponse.data;

    if (["rejected", "canceled", "outdated"].includes(deal_status)) {
      setDealData(dealResponse.data);
      return;
    }

    const partnerDealStatus = partners.find(
      (partner) => partner.email === partnerEmail
    )?.dealStatus;

    if (
      (isMediator || walletConnected === deal?.mediator?.wallet) &&
      mediator_deal_status === "approved"
    ) {
      setDealData(dealResponse.data);
      return;
    }

    if (partnerDealStatus === "approved") {
      setDealData(dealResponse.data);
      return;
    }

    try {
      let transactionHash;
      let backResponseResult;
      setIsApproveLoading(true);

      const walletExists = partners.some((partner) =>
        partner.partnerId.deals.some(
          (d) => d.deal === deal._id && d.wallet === user.walletAddress
        )
      );
      if (
        isMediator
          ? [deal?.creator?.wallet].includes(user.walletAddress) || walletExists
          : [deal?.creator?.wallet, deal?.mediator?.wallet].includes(
              user.walletAddress
            ) || walletExists
      ) {
        setWalletWarning(true);
        setIsApproveLoading(false);
        return;
      }

      const approveTx = isMediator
        ? DshareSC.methods.MediatorDealResponse(deal.sc_id, 1)
        : DshareSC.methods.PatnerDealResponse(deal.sc_id, 1, partnerEmail);

      if (!isMediator && isFundingWallet) {
        const decimals = await getTokenDecimals(TokenSC);
        const balanceInEther = await getAndConvertBalance(
          user.walletAddress,
          TokenSC
        );

        if (deal.gross_amount > balanceInEther) {
          setIsApproveLoading(false);
          setInsufficientFunds(true);
          return;
        }

        const grossAmountWei = Number(
          (deal.gross_amount * 10 ** Number(decimals)).toFixed(2)
        );
        await TokenSC.methods
          .approve(dshareAddress, grossAmountWei)
          .send({ from: user.walletAddress });
      }

      await approveTx
        .send({ from: user.walletAddress })
        .on("transactionHash", async (hash) => {
          transactionHash = hash;
          const apiCall = isMediator
            ? dealApi.updateMediatorDealStatus(
                deal._id,
                "approved",
                transactionHash
              )
            : dealApi.updatePartnerDealStatus(
                deal._id,
                partnerId,
                "approved",
                user.walletAddress,
                transactionHash
              );

          backResponseResult = await apiCall;

          if (!hash) throw new Error("Invalid transaction hash");

          const etherScanApiKey = process.env.REACT_APP_SCAN_API_KEY;
          const checkTransaction = async () => {
            const response = await axios.get(
              `https://api.polygonscan.com/api?module=proxy&action=eth_getTransactionReceipt&txhash=${hash}&apikey=${etherScanApiKey}`
            );

            if (response.data.error)
              throw new Error(response.data.error.message);
            return response.data;
          };

          const intervalId = setInterval(async () => {
            const responseData = await checkTransaction();
            if (
              responseData.result &&
              responseData.result.status === "0x1" &&
              backResponseResult.status === 200
            ) {
              clearInterval(intervalId);
              setIsApproved(true);
              const dealRes = await dealApi.getDeal(deal._id);
              setDealData(dealRes.data);
            }
          }, 5000);
        });
    } catch (error) {
      if (!error.message.includes("not mined within")) {
        setIsApproveLoading(false);
        console.error("Error in handleApprove:", error);
      }
    }
  };

  const handleReject = async () => {
    const dealResponse = await dealApi.getDeal(deal._id);
    const { deal_status, mediator_deal_status } = dealResponse.data;

    if (["rejected", "canceled", "outdated"].includes(deal_status)) {
      setDealData(dealResponse.data);
      return;
    }

    if (
      ((isMediator || walletConnected === deal?.mediator?.wallet) &&
        mediator_deal_status === "approved") ||
      mediator_deal_status === "rejected"
    ) {
      setDealData(dealResponse.data);
      return;
    }

    try {
      setIsLoading(true);

      const walletExists = deal.partners.some((partner) =>
        partner.partnerId.deals.some(
          (d) => d.deal === deal._id && d.wallet === user.walletAddress
        )
      );

      if (
        isMediator
          ? [deal?.creator?.wallet].includes(user.walletAddress) || walletExists
          : [deal?.creator?.wallet, deal?.mediator?.wallet].includes(
              user.walletAddress
            ) || walletExists
      ) {
        setWalletWarning(true);
        setIsLoading(false);
        return;
      }

      const rejectApiCall = isMediator
        ? dealApi.updateMediatorDealStatus(deal._id, "rejected")
        : dealApi.updatePartnerDealStatus(
            deal._id,
            partnerId,
            "rejected",
            user.walletAddress
          );

      await rejectApiCall;

      setIsRejected(true);
      if (typeof onDealRejection === "function") {
        onDealRejection();
      }
      const dealRes = await dealApi.getDeal(deal._id);
      setDealData(dealRes.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error in handleReject:", error);
    }
  };

  const closeFailureModal = () => {
    setInsufficientFunds(false);
  };

  return (
    <div className="flex items-center">
      {!isApproved && !isRejected && (
        <>
          <button
            type="button"
            className={`w-[95px] h-[55px] flex items-center justify-center text-base font-bold text-black bg-[#EE00E5] rounded-xl mr-2 ${
              ["rejected", "canceled", "outdated"].includes(deal.deal_status) ||
              isLoading
                ? "opacity-50"
                : ""
            }`}
            onClick={handleApprove}
            disabled={
              ["rejected", "canceled", "outdated"].includes(deal.deal_status) ||
              isLoading
            }
          >
            {isApproveLoading ? (
              <div className="w-10 h-10">
                <LoadingSpinner />
              </div>
            ) : (
              "Join"
            )}
          </button>
          <button
            type="button"
            className={`w-[95px] h-[55px] flex items-center justify-center text-base font-bold text-black bg-[#EE0000] rounded-xl ${
              ["rejected", "canceled", "outdated"].includes(deal.deal_status) ||
              isApproveLoading
                ? "opacity-50"
                : ""
            }`}
            onClick={handleReject}
            disabled={
              ["rejected", "canceled", "outdated"].includes(deal.deal_status) ||
              isApproveLoading
            }
          >
            {isLoading ? (
              <div className="w-10 h-10">
                <LoadingSpinner />
              </div>
            ) : (
              "Reject"
            )}
          </button>
        </>
      )}
      {isApproved && <p>Joined</p>}
      {isRejected && <p>Rejected</p>}

      {isApproveLoading ? (
        isFundingWallet ? (
          <FundingModal />
        ) : (
          <NotFundingModal />
        )
      ) : null}
      {insufficientFunds && (
        <FailureModal
          title="Insufficient Funds !"
          text="You don't have enough funds to create the deal"
          closeModal={closeFailureModal}
        />
      )}
    </div>
  );
}

export default ApprovalButton;
