/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import GenericButton from "../buttons/GenericButton";

function GenericModal({
  closeModal,
  title,
  buttonColor,
  buttonText,
  onButtonClick,
  children,
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-eclipse-blue xl:w-[20%]  md:h-96 flex flex-col items-center justify-center rounded shadow px-12 py-12 border-4 border-ice-blue text-white space-y-4 relative"
      >
        <button
          type="button"
          onClick={closeModal}
          className="absolute top-3 right-3 text-white cursor-pointer"
        >
          X
        </button>
        <h2 className="text-2xl font-bold mb-2 text-center">{title}</h2>
        {children}

        <div className="h-[55px] w-[200px]">
          <GenericButton
            color={buttonColor}
            text={buttonText}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </div>
  );
}

GenericModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default GenericModal;
