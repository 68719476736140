/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dealApi } from "../api/api";

// eslint-disable-next-line import/no-named-as-default
import LoadingSpinner from "../components/LoadingSpinner";
import DealDetailsTable from "../components/deal/table/DealDetailsTable";

function DealDetails() {
  const { id } = useParams();
  const [deal, setDeal] = useState();

  const [isLoading, setIsLoading] = useState(false);

  // Get Deal
  useEffect(() => {
    const getDeal = async () => {
      try {
        setIsLoading(true);
        const dealRes = await dealApi.getDeal(id);
        setDeal(dealRes.data);

        setIsLoading(false);
      } catch (error) {
        console.log({ error });
      }
    };

    getDeal();
  }, [id]);

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : <DealDetailsTable deal={deal} />}
    </div>
  );
}

export default DealDetails;
