import React from "react";
import PropTypes from "prop-types";

function Indicators({ indicator }) {
  const labels = [
    "Deal Information",
    "Deal Parameters",
    "Validation Method",
    "Arbitration",
    "Review & Confirm",
  ];
  return (
    <div className="mb-8">
      <hr className="border-1 border-t border-[#00e2ff] w-full mb-8" />
      <div className="flex justify-around items-center">
        <div className="flex justify-center items-center space-x-24">
          {labels.map((label, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`flex items-center justify-between ${
                index < 4 ? "mr-4" : ""
              }`}
            >
              <div
                className={`w-6 h-6 bg-[#00e2ff] rounded-full flex items-center justify-center ${
                  index <= indicator
                    ? ""
                    : "bg-gradient-to-t from-deep-navy to-steel-blue border border-1 border-[#00e2ff]"
                } `}
              >
                <p
                  className={`${
                    index <= indicator ? "text-black" : "text-white"
                  }`}
                >
                  {index + 1}
                </p>
              </div>
              <p className="text-l text-white ml-4">{label}</p>
            </div>
          ))}
        </div>
        <p className="text-white text-l font-bold underline">Save draft</p>
      </div>
      <hr className="border-1 border-t border-[#00e2ff] w-full mt-8" />
    </div>
  );
}

Indicators.propTypes = {
  indicator: PropTypes.number.isRequired,
};
export default Indicators;
