/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { setMediatorStatus } from "../store/features/mediatorSlice/mediatorSlice";
import logo from "../assets/images/logo-dshare-vecto.png";
import { dealApi } from "../api/api";
import LoadingSpinner from "../components/LoadingSpinner";
import WarningModal from "../components/modals/WarningModal";
import ConnectToMetamaskButton from "../components/buttons/ConnectToMetamaskButton";

function ConnectWalletMediator() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const isConnectedFromRedux = useSelector((state) => state.user.isConnected);
  const { isConnected, address } = useAccount();

  const [deal, setDeal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    // Recover URL
    const url = window.location.href;

    // Check if the word mediator exists in the URL
    const isMediator = url.includes("mediator");
    dispatch(setMediatorStatus(isMediator));
  }, [dispatch]);

  useEffect(() => {
    if (isConnected && isConnectedFromRedux && deal) {
      if (deal.mediator.wallet.toLowerCase() !== address.toLocaleLowerCase())
        setShowWarning(true);
      else navigate(`/deal/${id}`);
    }
  }, [isConnected]);
  useEffect(() => {
    const getDeal = async () => {
      setIsLoading(true);
      const dealRes = await dealApi.getDeal(id);
      setDeal(dealRes.data);

      setIsLoading(false);
    };

    getDeal();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center w-screen h-screen ">
          <div className="w-10 h-10">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-steel-blue via-deep-navy to-eclipse-blue">
          <div className="flex flex-col items-center space-y-6">
            <img src={logo} alt="logo" className="w-48 mx-auto" />
            <div className="text-center">
              <p className="text-white max-w-[80vw]">
                {deal?.creator?.email} invited you to be the arbitrator of the
                deal {deal?.name}
              </p>
            </div>
            <div className="flex justify-center">
              <ConnectToMetamaskButton
                isFromMediator
                mediatorWallet={deal?.mediator.wallet}
                setShowWarning={setShowWarning}
              />
            </div>
          </div>
        </div>
      )}
      {showWarning && (
        <WarningModal
          title="Wrong metamask wallet!"
          text={`You should connect this wallet chosen by the deal creator ${deal?.mediator.wallet.slice(
            0,
            4
          )}...${deal?.mediator.wallet.slice(-4)} `}
          setShowWarning={setShowWarning}
        />
      )}
    </div>
  );
}

export default ConnectWalletMediator;
