/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import logo from "../assets/images/logo-dshare-vecto.png";
import ConnectToMetamaskButton from "../components/buttons/ConnectToMetamaskButton";
import { dealApi, partnerApi } from "../api/api";
import LoadingSpinner from "../components/LoadingSpinner";
import WarningModal from "../components/modals/WarningModal";

function ConnectWalletPartner() {
  const navigate = useNavigate();
  const { did, pid } = useParams();
  const isConnectedFromRedux = useSelector((state) => state.user.isConnected);
  const { isConnected, address } = useAccount();
  const [deal, setDeal] = useState();
  const [partner, setPartner] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [otherPartners, setOtherPartners] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const { disconnect } = useDisconnect();

  useEffect(() => {
    const getDealAndPartner = async () => {
      setIsLoading(true);
      const dealResponse = await dealApi.getDeal(did);

      if (dealResponse) {
        setDeal(dealResponse.data);
      }
      const partnerResponse = await partnerApi.getPartnerById(pid);
      if (partnerResponse) setPartner(partnerResponse.data);

      // Check if wallet exist in other partner
      setOtherPartners(
        dealResponse.data.partners.filter(
          (p) => p.email !== partnerResponse.data.email
        )
      );
      setIsLoading(false);
    };
    getDealAndPartner();
  }, []);
  useEffect(() => {
    disconnect();
  }, []);
  useEffect(() => {
    if (isConnected && isConnectedFromRedux) {
      if (address.toLocaleLowerCase() !== deal?.mediator?.wallet)
        navigate(`/deal/${did}`);
      else setShowWarning(true);
    }
  }, [isConnected, address]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-steel-blue via-deep-navy to-eclipse-blue">
      {isLoading ? (
        <div className="w-10 h-10 absolute translate-y-4 left-1/2 ">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-6">
          <img src={logo} alt="logo" className="w-48 mx-auto" />
          <div className="text-center">
            <p className="text-white max-w-[80vw]">
              {deal?.creator?.email} invited you for a new deal! Connect your
              wallet to join the deal.
            </p>
            {deal?.funding_wallet === partner?.email && (
              <div className="text-white max-w-[80vw]">
                {" "}
                <p className="text-red-500 font-bold"> WARNING</p>{" "}
                <p>
                  You have been selected as the Funding Wallet. You will have to
                  lock the deal amount in the smart contract.
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <ConnectToMetamaskButton
              emailConnected={partner?.email}
              otherPartners={otherPartners}
              setShowWarning={setShowWarning}
              dealId={did}
            />
          </div>
        </div>
      )}
      {showWarning && (
        <WarningModal
          title="metamask wallet exist!"
          text="This metamask wallet is already used by other member in this deal. Please choose another wallet ! "
          setShowWarning={setShowWarning}
        />
      )}
    </div>
  );
}

export default ConnectWalletPartner;
