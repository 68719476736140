/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { useConnect } from "wagmi";
import { injected } from "wagmi/connectors";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { CONNECT_WALLET } from "../../store/features/walletSlice/walletSlice";
import { dealApi } from "../../api/api";

function ConnectToMetamaskButton({
  emailConnected,
  isFromMediator,
  mediatorWallet,
  setShowWarning,
  otherPartners,
  dealId,
}) {
  const dispatch = useDispatch();
  const { connect } = useConnect();
  const connectToMetamask = async () => {
    try {
      const currentDeal = await dealApi.getDeal(dealId);

      if (window.ethereum) {
        if (window.ethereum.chainId === "0x89") {
          // Requesting access to accounts
          await window.ethereum.request({ method: "eth_requestAccounts" });

          // Get the connected accounts
          const accounts = await window.ethereum.request({
            method: "eth_accounts",
          });

          const connectedWallet = accounts[0];
          if (isFromMediator) {
            if (
              connectedWallet.toLowerCase() !== mediatorWallet.toLowerCase()
            ) {
              setShowWarning(true);
            } else {
              // Dispatch action to update store
              dispatch(
                CONNECT_WALLET({
                  metamaskAddress: connectedWallet,
                  emailConnected,
                })
              );
              connect({ connector: injected() });
            }
          } else {
            // Check if the given wallet is already associated with another partner in the same deal
            let walletExist = false;
            otherPartners.forEach((partner) => {
              partner.partnerId.deals.forEach((deal) => {
                if (deal.deal === dealId && deal.wallet === connectedWallet) {
                  walletExist = true;
                }
              });
            });

            if (
              currentDeal?.data?.mediator &&
              connectedWallet === currentDeal?.data?.mediator?.wallet
            ) {
              setShowWarning(true);
              return;
            }
            if (walletExist) setShowWarning(true);
            else {
              // Dispatch action to update store
              dispatch(
                CONNECT_WALLET({
                  metamaskAddress: connectedWallet,
                  emailConnected,
                })
              );
              connect({ connector: injected() });
            }
          }
        } else {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x89" }],
          });
        }
      } else {
        // Metamask is not installed or not enabled
        console.log("Metamask is not installed or not enabled");
      }
    } catch (error) {
      // Error occurred during connection
      console.log("Error connecting to Metamask:", error);
    }
  };

  return (
    <button
      type="button"
      className="bg-gradient-to-l from-ice-blue to-steel-blue w-full h-full rounded-2xl text-white px-8 py-2"
      onClick={connectToMetamask}
    >
      Connect wallet
    </button>
  );
}

ConnectToMetamaskButton.defaultProps = {
  emailConnected: "",
  isFromMediator: false,
  setShowWarning: false,
  mediatorWallet: "",
  otherPartners: [],
  dealId: "",
};

ConnectToMetamaskButton.propTypes = {
  emailConnected: PropTypes.string,
  isFromMediator: PropTypes.bool,
  setShowWarning: PropTypes.bool,
  mediatorWallet: PropTypes.string,
  otherPartners: PropTypes.arrayOf(
    PropTypes.shape({
      // Define the structure of each partner object inside the array
      partnerId: PropTypes.shape({
        // Define the structure of the partnerId object
        deals: PropTypes.arrayOf(
          PropTypes.shape({
            // Define the structure of the deal object
            deal: PropTypes.string.isRequired,
            wallet: PropTypes.string.isRequired,
          })
        ),
      }),
    })
  ),
  dealId: PropTypes.string,
};
export default ConnectToMetamaskButton;
