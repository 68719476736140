/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// Initial state

const initialState = {
  walletAddress: "",
  isConnected: false,
  emailConnected: "",
  isInstalled: true,
};

// Create the wallet slice

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    CONNECT_WALLET: (state, action) => {
      state.walletAddress = action.payload.metamaskAddress;
      state.isConnected = true;
      state.emailConnected = action.payload.emailConnected;
    },
    DISCONNECT_WALLET: (state) => {
      state.walletAddress = "";
      state.isConnected = false;
    },
    clearEmailConnected: (state) => {
      state.emailConnected = "";
    },
    SET_IS_INSTALLED: (state) => {
      state.isInstalled = false;
    },
  },
});

// Export the actions

export const {
  CONNECT_WALLET,
  DISCONNECT_WALLET,
  clearEmailConnected,
  SET_IS_INSTALLED,
} = walletSlice.actions;

// Export the reducer
export default walletSlice.reducer;
