/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactDOM from "react-dom/client";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider, http } from "wagmi";
import { polygon } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store/store";
import "@rainbow-me/rainbowkit/styles.css";

const config = getDefaultConfig({
  appName: "RainbowKit demo",
  projectId: "3978182387ccc6c684fabf4aee45bb63",
  chains: [polygon],
  transports: {
    [polygon.id]: http(
      "https://polygon-mainnet.g.alchemy.com/v2/hWukiO4RRsJDzSkagM90LB2zJQ3ilonq  "
    ),
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <App />
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
