import React from "react";

function InstallMetamaskModal() {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-eclipse-blue w-[70%] max-w-[600px] md:max-w-[30%] rounded shadow px-6 py-8 border-4 border-ice-blue text-white space-y-4">
        <h2 className="text-2xl font-bold mb-2 text-center mt-2">
          Install Metamask
        </h2>

        <div className="flex flex-col items-center">
          <p className="">
            Oops ! It seems like you do not have a wallet extension on this
            internet browser. Easily set up your Metamask wallet extension to
            use D-Share 🚀
          </p>
          <a
            href="https://metamask.io/"
            target="_blank"
            rel="noreferrer"
            className="  h-full rounded-full bg-gradient-to-l from-ice-blue to-steel-blue text-white font-bold py-[10px] px-3"
          >
            Install metamask
          </a>
        </div>
      </div>
    </div>
  );
}

export default InstallMetamaskModal;
