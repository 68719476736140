import React from "react";
import PropTypes from "prop-types";

function BlueIceButton({ text, onClick }) {
  return (
    <button
      type="button"
      className="bg-gradient-to-l from-ice-blue to-steel-blue w-full h-full rounded-2xl text-white px-8 py-2"
      onClick={onClick}
    >
      {text}
    </button>
  );
}
BlueIceButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BlueIceButton;
