/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import GenericButton from "../buttons/GenericButton";

function WarningModal({ title, text, closeModal, setShowWarning }) {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50"
      onClick={closeModal}
    >
      <div className="bg-eclipse-blue xl:w-[20%]  md:h-96 flex flex-col items-center justify-center rounded shadow px-12 py-12 border-4 border-ice-blue text-white space-y-4">
        <h2 className="text-2xl font-bold mb-2 text-center">{title}</h2>
        <p className="text-center">{text}</p>
        <div className="h-12 w-2/3">
          <GenericButton
            color="bg-gradient-to-r from-white to-button-pink"
            text="Ok"
            onClick={() => setShowWarning(false)}
          />
        </div>
      </div>
    </div>
  );
}
WarningModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  setShowWarning: PropTypes.func.isRequired,
};
export default WarningModal;
