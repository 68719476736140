/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import axios from "axios";
import { useAccount } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContractDshare from "../../../contracts/dshare/ABI_DShare";
import { dealApi } from "../../../api/api";
import eyeIcon from "../../../assets/icons/eyeIcon.svg";
import LoadingSpinner from "../../LoadingSpinner";
import FailureModal from "../../modals/FailureModal";
import SuccessModal from "../../modals/SuccessModal";
import GenericButton from "../../buttons/GenericButton";
import { SET_IS_INSTALLED } from "../../../store/features/walletSlice/walletSlice";
import NotFundingModal from "../../modals/NotFundingModal";

const dshareAddress = process.env.REACT_APP_DSHARE_SC;
const web3 = new Web3(window.ethereum);
const DshareSC = new web3.eth.Contract(ContractDshare.ABI, dshareAddress);

function DealsListTable() {
  const navigate = useNavigate();

  const { isConnected, address } = useAccount();

  const walletConnected = useSelector((state) => state.user.walletAddress);
  const dispatch = useDispatch();

  const [showSuccessUnlockedModal, setShowSuccessUnlockedModal] =
    useState(false);
  const [showFailureUnlockedModal, setShowFailureUnlockedModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [indiceClicked, setIndiceClicked] = useState(null);
  const [deals, setDeals] = useState([]);
  const [isArchived, setIsArchived] = useState(false);
  const [marginLeftClass, setMarginLeftClass] = useState("");
  const [correctNetwork, setCorrectNetwork] = useState(true);

  const closeSuccessUnlockedlModal = () => {
    setShowSuccessUnlockedModal(false);
  };
  const closeFailureUnlockedModal = () => {
    setShowFailureUnlockedModal(false);
  };

  const handleNetworkChange = (chain) => {
    setCorrectNetwork(chain === "0x89");
  };

  const checkNetwork = async () => {
    try {
      if (window.ethereum) {
        const id = await window.ethereum.request({ method: "net_version" });
        setCorrectNetwork(id === "137");
      } else {
        dispatch(SET_IS_INSTALLED());
        console.log("Please install Metamask");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 905) setMarginLeftClass("mx-32");
    else if (screenWidth < 1502) setMarginLeftClass("mx-36");
    else setMarginLeftClass("ml-0");
  };

  const getDealsByWallet = async () => {
    if (!address) return;

    try {
      setIsLoading(true);
      const dealsRes = await dealApi.getDealsByWallet(address.toLowerCase());
      setDeals(dealsRes.data ? dealsRes.data.deals : []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching deals:", error);
    }
  };

  const archiveDeal = async (deal) => {
    await dealApi.archiveDeal(deal, address.toLowerCase());
    setIsArchived(!isArchived);
  };

  const handleUnlockFund = async (deal) => {
    try {
      setIndiceClicked(deal._id);
      let transactionHash;
      let backResponseResult;
      const UnlockFundTx = DshareSC.methods.UnlockFund(deal.sc_id);

      await UnlockFundTx.send({ from: walletConnected }).on(
        "transactionHash",
        async (hash) => {
          transactionHash = hash;
          backResponseResult = await dealApi.unlockFunds(
            deal._id,
            walletConnected,
            transactionHash
          );
          const etherScanApiKey = process.env.REACT_APP_SCAN_API_KEY;

          if (!hash) {
            throw new Error("Invalid transaction hash");
          }

          const intervalId = setInterval(async () => {
            try {
              const response = await axios.get(
                `https://api.polygonscan.com/api?module=proxy&action=eth_getTransactionReceipt&txhash=${hash}&apikey=${etherScanApiKey}`
              );

              if (response.data.error) {
                throw new Error(response.data.error.message);
              }

              const responseData = response.data;
              if (
                responseData.result &&
                responseData.result.status === "0x1" &&
                backResponseResult.status === 200
              ) {
                clearInterval(intervalId);
                const dealsRes = await dealApi.getDealsByWallet(
                  address.toLowerCase()
                );
                setDeals(dealsRes.data.deals);
                setIndiceClicked(null);
                setShowSuccessUnlockedModal(true);
              }
            } catch (error) {
              console.error(error);
            }
          }, 5000);
        }
      );
    } catch (error) {
      console.error(error);
      if (!error.message.includes("not mined within")) {
        setIndiceClicked(null);
        setShowFailureUnlockedModal(true);
      }
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleNetworkChange);
    }

    checkNetwork();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", handleNetworkChange);
      }
    };
  }, []);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getDealsByWallet();
  }, [address, isArchived]);
  return (
    <div className={marginLeftClass}>
      <table>
        <thead className="rounded-lg w-full h-[140px] px-4 outline outline-offset-0 outline-4 outline-ice-blue bg-gradient-to-b from-from-deep-navy to-deep-navy text-white text-left">
          <tr>
            <th className="px-12 2xl:text-xl mb-2 text-xs">Dealname</th>
            <th className="px-5 2xl:text-xl mb-2 text-xs">Currency</th>
            <th className="px-5 2xl:text-xl mb-2 text-xs">Amount</th>
            <th className="px-5 2xl:text-xl mb-2 text-xs">Partners</th>
            <th className="px-5 2xl:text-xl mb-2 text-xs">Expiry date</th>
            <th className="px-5 2xl:text-xl mb-2 text-xs">Deal Status</th>
            <th className="px-5 2xl:text-xl mb-2 text-xs">Validation Method</th>
            <th className="px-12 2xl:text-xl mb-2 text-xs ">Service Status</th>
          </tr>
        </thead>

        {isLoading ? (
          <tbody className="text-white">
            <tr>
              <td colSpan="7" className="text-center py-5">
                <div className="w-full flex items-center justify-center">
                  <div className="w-10 h-10 ml-44">
                    <LoadingSpinner />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          isConnected &&
          deals?.length > 0 &&
          correctNetwork === true && (
            <tbody className="text-white">
              {deals?.map((deal) => (
                <tr key={deal._id} className="border border-ice-blue">
                  <td className="px-5 py-6 flex w-48">
                    <div
                      className={`rounded-full min-w-[24px] min-h-[24px]  w-6 h-6  ${
                        deal.deal_status === "rejected" ||
                        deal.deal_status === "canceled" ||
                        deal.service_status === "mediation" ||
                        deal.deal_status === "outdated" ||
                        deal.service_status === "rejected"
                          ? "bg-warning-red"
                          : deal.service_status === "pending"
                          ? "bg-orange-500"
                          : "bg-green-500"
                      }`}
                    />
                    <p className="pl-4 flex">
                      {deal.name.length > 14 && deal.name.indexOf(" ") === -1
                        ? `${deal.name.slice(0, 10)}...`
                        : deal.name}
                    </p>
                  </td>
                  <td className="px-5 py-6">{deal?.currency}</td>
                  <td className="px-5 py-6">{deal?.amount}</td>
                  <td className="px-5 py-6">
                    {(deal?.partners?.length || 0) +
                      1 +
                      (deal?.mediator ? 1 : 0)}
                  </td>
                  <td className="px-5 py-6">
                    {deal?.deadline
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                  </td>
                  <td
                    className={`px-5 py-6 font-bold  ${
                      (deal?.deal_status === "canceled" ||
                        deal?.deal_status === "rejected" ||
                        deal.deal_status === "outdated") &&
                      "text-warning-red"
                    }  `}
                  >
                    {deal?.deal_status?.charAt(0).toUpperCase() +
                      deal?.deal_status?.slice(1)}
                  </td>
                  {/* Service status  */}
                  {/* -------- Validation Method ------------ */}
                  <td className="px-5 py-6">
                    <p>Partners Signature</p>
                  </td>
                  {/* -------- Service Status ---------------  */}
                  <td className="px-5 py-6 font-bold ">
                    <div className="w-full h-full flex justify-end space-x-4">
                      <p
                        className={`${
                          (deal.service_status === "canceled" ||
                            deal.service_status === "mediation" ||
                            deal.service_status === "rejected") &&
                          "text-warning-red"
                        } ${
                          deal.service_status === "validated" &&
                          "text-green-500"
                        }`}
                      >
                        {((deal.deal_status === "approved" &&
                          deal.service_status === "pending") ||
                          deal.service_status === "validated" ||
                          deal.service_status === "mediation" ||
                          (deal.service_status === "rejected" &&
                            deal.funds_unlocked) ||
                          (deal.service_status === "rejected" &&
                            !deal.funds_unlocked &&
                            !deal?.partners?.some((item) => {
                              const partnerDeal = item.partnerId?.deals?.find(
                                (d) => d.deal === deal._id
                              );

                              return (
                                partnerDeal?.wallet === walletConnected &&
                                item.email === deal?.funding_wallet
                              );
                            }) &&
                            !(
                              deal?.funding_wallet === deal?.creator?.email &&
                              deal?.creator?.wallet === walletConnected
                            ))) &&
                          (deal?.service_status === "mediation"
                            ? "Arbitration"
                            : deal?.service_status?.charAt(0).toUpperCase() +
                              deal?.service_status?.slice(1))}
                      </p>
                      {(!deal.funds_unlocked &&
                        (deal?.deal_status === "rejected" ||
                          deal?.deal_status === "outdated" ||
                          deal?.service_status === "rejected") &&
                        (deal?.partners?.some((item) => {
                          const partnerDeal = item.partnerId?.deals?.find(
                            (d) => d.deal === deal._id
                          );

                          return (
                            partnerDeal?.wallet === walletConnected &&
                            item.email === deal?.funding_wallet &&
                            item.dealStatus === "approved"
                          );
                        }) ||
                          (deal?.funding_wallet === deal?.creator?.email &&
                            deal?.creator?.wallet === walletConnected))) ||
                      (deal?.partners?.some((item) => {
                        const partnerDeal = item.partnerId?.deals?.find(
                          (d) => d.deal === deal._id
                        );

                        return (
                          partnerDeal?.wallet === walletConnected &&
                          item.email === deal?.funding_wallet &&
                          item.dealStatus === "approved"
                        );
                      }) &&
                        deal?.deal_status === "canceled") ? (
                        <div className="h-10 w-32">
                          <GenericButton
                            text={
                              indiceClicked === deal._id ? (
                                <div className="w-10 h-10">
                                  {" "}
                                  <LoadingSpinner />
                                </div>
                              ) : (
                                "Unlock funds"
                              )
                            }
                            color="bg-[#D8E7E8]"
                            onClick={() => handleUnlockFund(deal)}
                          />
                        </div>
                      ) : (
                        (deal.deal_status === "canceled" ||
                          deal.deal_status === "rejected" ||
                          deal.deal_status === "outdated" ||
                          deal.service_status === "rejected") && (
                          <button
                            className="w-6"
                            type="button"
                            onClick={() => archiveDeal(deal._id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#ec0a00"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </button>
                        )
                      )}

                      <div className="flex flex-row space-x-2">
                        <button
                          type="button"
                          onClick={() => navigate(`/deal/${deal._id}`)}
                          className="w-4 ml-1"
                        >
                          <img src={eyeIcon} alt="eyeIcon" />
                        </button>
                        {deal.transaction_hash ? (
                          <a
                            href={`https://polygonscan.com/tx/${deal.transaction_hash}`}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center justify-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                              />
                            </svg>
                          </a>
                        ) : (
                          <div className="w-6 h-6" />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {showSuccessUnlockedModal && (
                <SuccessModal
                  title="Funds unlocked successfully!"
                  text=""
                  closeModal={closeSuccessUnlockedlModal}
                  onButtonClick={closeSuccessUnlockedlModal}
                />
              )}
              {showFailureUnlockedModal && (
                <FailureModal
                  title="Oops!"
                  text="An error has occurred while unlocking funds"
                  closeModal={closeFailureUnlockedModal}
                />
              )}
              {indiceClicked && <NotFundingModal />}
            </tbody>
          )
        )}
      </table>

      {!isLoading && isConnected && deals?.length === 0 && (
        <p className="text-white text-center p-10 bg-eclipse-blue border border-ice-blue">
          No deals found for the connected wallet.
        </p>
      )}
      {!isLoading && (!isConnected || !correctNetwork) && (
        <p className="text-white text-center p-10 bg-eclipse-blue border border-ice-blue">
          Connect your wallet to see your deals or create a new one...
        </p>
      )}
    </div>
  );
}

export default DealsListTable;
