import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./layouts/Layout";
import Dashboard from "./pages/Dashboard";
import NewDeal from "./pages/NewDeal";
import Sourcing from "./pages/Sourcing";
import DealDetails from "./pages/DealDetails";
import ConnectWalletPartner from "./pages/ConnectWalletPartner";
import ConnectWalletMediator from "./pages/ConnectWalletMediator";

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/new-deal" element={<NewDeal />} />
        <Route path="/sourcing" element={<Sourcing />} />
        <Route path="/deal/:id" element={<DealDetails />} />
      </Route>

      <Route
        path="/connect-wallet-partner/:did/:pid"
        element={<ConnectWalletPartner />}
      />
      <Route
        path="/connect-wallet-mediator/:id"
        element={<ConnectWalletMediator />}
      />
      {/* <Route path="/" element={<LandingPage />} /> */}
    </Routes>
  );
}

export default App;
