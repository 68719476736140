import React from "react";
import PropTypes from "prop-types";

function GenericButton({ color, text, onClick }) {
  return (
    <button
      type="button"
      className={`w-full h-full rounded-full ${color} text-black font-bold flex justify-center items-center`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
GenericButton.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default GenericButton;
