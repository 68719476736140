/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from "react";
import Web3 from "web3";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAccount, useChainId, useChains } from "wagmi";
import { useSelector } from "react-redux";
import TableRow from "./TableRow";
import ApprovalButton from "../../buttons/ApprovalButton";
import GenericButton from "../../buttons/GenericButton";
import GenericModal from "../../modals/GenericModal";
import ContractDshare from "../../../contracts/dshare/ABI_DShare";
import { dealApi, mediatorApi, partnerApi, creatorApi } from "../../../api/api";
import LoadingSpinner from "../../LoadingSpinner";
import FailureModal from "../../modals/FailureModal";
import SuccessModal from "../../modals/SuccessModal";
import WarningModal from "../../modals/WarningModal";
import NotFundingModal from "../../modals/NotFundingModal";
import {
  fetchAndCheckDealStatus,
  executeTransaction,
} from "../../../utils/utils";

const dshareAddress = process.env.REACT_APP_DSHARE_SC;
const web3 = new Web3(window.ethereum);
const DshareSC = new web3.eth.Contract(ContractDshare.ABI, dshareAddress);

function DealDetailsTable({ deal }) {
  const emailConnected = useSelector((state) => state.user.emailConnected);
  const { address, isConnected } = useAccount();
  const chainId = useChainId();
  const chains = useChains();
  const walletConnected = address?.toLowerCase(); // useSelector((state) => state.walletConnected);

  const isMediator = useSelector((state) => state.mediator);
  const user = useSelector((state) => state.user);
  const [dealData, setDealData] = useState(deal);
  const [isDealCancelled, setIsDealCancelled] = useState(false);
  const [openCallReason, setOpenCallReason] = useState(false);
  const [openCallPartnerReason, setOpenCallPartnerReason] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openPartnerConfirmation, setOpenPartnerConfirmation] = useState(false);
  const [goConfirmation, setGoConfirmation] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false);
  const [reason, setReason] = useState("");
  const [showSuccessCallModal, setShowSuccessCallModal] = useState(false);
  const [showFailureCallModal, setShowFailureCallModal] = useState(false);

  const [showSuccessUnlockedModal, setShowSuccessUnlockedModal] =
    useState(false);
  const [showFailureUnlockedModal, setShowFailureUnlockedModal] =
    useState(false);
  const [showSuccessValidateModal, setShowSuccessValidateModal] =
    useState(false);
  const [showFailureValidateModal, setShowFailureValidateModal] =
    useState(false);
  const [showSuccessRejectModal, setShowSuccessRejectModal] = useState(false);
  const [showFailureRejectModal, setShowFailureRejectModal] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [marginLeftClass, setMarginLeftClass] = useState("");
  const [walletWarning, setWalletWarning] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [showCreatorRejectPopup, setShowCreatorRejectPopup] = useState(false);
  const refIdPartner = useRef(null);
  const navigate = useNavigate();
  // Calculate number of partners
  let numberPartners = dealData?.partners.length + 1;
  if (deal?.mediator) numberPartners += 1;

  // Verify if all partners approved the deal
  const allPartnersApproved = dealData?.partners?.every(
    (partner) => partner.dealStatus === "approved"
  );

  // Verify if mediator exists & approved the deal
  const mediatorApproved =
    !dealData?.mediator || dealData?.mediator_deal_status === "approved";

  // Verify if all members approved the deal
  const allMembersApproved = allPartnersApproved && mediatorApproved;

  useEffect(() => {
    const detectNetworkChange = async () => {
      if (window.ethereum) {
        window.ethereum.on("chainChanged", (chain) => {
          // Here, you can handle the network change event
          // For example, you might want to update some state to reflect the new network
          if (chain !== "137") navigate("/dashboard");
        });
      }
    };

    detectNetworkChange();

    // Cleanup function to remove the event listener
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", detectNetworkChange);
      }
    };
  }, []);

  useEffect(() => {
    if (!isConnected) navigate("/dashboard");
  }, [isConnected]);

  // Check network on component render, if not 137, navigate to dashboard
  useEffect(() => {
    const checkNetwork = async () => {
      try {
        if (window.ethereum) {
          const id = await window.ethereum.request({ method: "net_version" });
          if (id !== "137") navigate("/dashboard");
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkNetwork();
  }, []);

  useEffect(() => {
    // Fonction de gestionnaire de changement de taille d'écran
    function handleWindowSizeChange() {
      // Détection de la largeur de l'écran
      const screenWidth = window.innerWidth;

      // Appliquer la classe en fonction de la largeur de l'écran
      if (screenWidth < 905) {
        setMarginLeftClass("mx-32"); // Petite taille d'écran
      } else if (screenWidth < 1502) {
        setMarginLeftClass("mx-36"); // Taille d'écran moyenne
      } else {
        setMarginLeftClass("mx-10"); // Grande taille d'écran
      }
    }

    // Écouter les changements de taille de l'écran
    window.addEventListener("resize", handleWindowSizeChange);

    // Appeler la fonction pour définir la classe initiale
    handleWindowSizeChange();

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleDealRejection = () => {
    setIsDealCancelled(true);
  };

  const handleInputChange = (event) => {
    setReason(event.target.value);
  };

  // Validate partner service status
  const validatePartnerServiceStatus = async (partnerId) => {
    const { isCommonConditionMet, newDeal } = await fetchAndCheckDealStatus(
      dealData._id,
      setDealData
    );

    if (isCommonConditionMet) {
      return;
    }

    // Find the right partner
    const partner_service_status = newDeal?.partners.find(
      (partner) =>
        partner.partnerId?.deals.find((d) => d.deal === dealData._id)
          ?.wallet === walletConnected
    )?.serviceStatus;

    if (partner_service_status === "validated") {
      setDealData(newDeal);
      return;
    }
    try {
      const validateServiceTx = DshareSC.methods.PatnerDealValidation(
        dealData.sc_id,
        1
      );

      // Utiliser executeTransaction pour gérer la transaction
      await executeTransaction(
        validateServiceTx,
        dealData._id,
        walletConnected,
        (dealId, walletAddress, hash) =>
          partnerApi.validatePartnerServiceStatus(dealId, partnerId, hash),
        process.env.REACT_APP_SCAN_API_KEY,
        setValidateLoading,
        setShowSuccessValidateModal,
        setDealData,
        setShowFailureValidateModal
      );
    } catch (error) {
      console.error("Error in validating service :", error);
      setValidateLoading(false);
      setShowFailureValidateModal(true);
    }
  };

  // Reject partner service status
  const rejectPartnerServiceStatus = async (partnerId) => {
    const { isCommonConditionMet, newDeal } = await fetchAndCheckDealStatus(
      dealData._id,
      setDealData
    );

    if (isCommonConditionMet) {
      return;
    }

    // Find the right partner
    const partner_service_status = newDeal?.partners.find(
      (partner) =>
        partner.partnerId?.deals.find((d) => d.deal === dealData._id)
          ?.wallet === walletConnected
    )?.serviceStatus;

    if (
      partner_service_status === "validated" ||
      partner_service_status === "rejected"
    ) {
      setDealData(newDeal);
      return;
    }

    try {
      // nbr partner + 1 creator
      const nbrTotal = newDeal?.partners.length + 1;

      let nbrReject = newDeal?.partners.filter(
        (partner) => partner.serviceStatus === "rejected"
      ).length;

      if (newDeal?.creator_service_status === "rejected") nbrReject += 1;

      const isLastReject = nbrTotal - nbrReject === 1;

      if (isLastReject) {
        refIdPartner.current = partnerId;
        setShowRejectPopup(true);
        return;
      }

      setRejectLoading(true);

      // Make the API call and wait for the response
      const backResponseResult = await partnerApi.rejectPartnerServiceStatus(
        dealData._id,
        partnerId
      );

      if (backResponseResult.status === 200) {
        setRejectLoading(false);
        setShowSuccessRejectModal(true);
        const dealRes = await dealApi.getDeal(dealData._id);
        setDealData(dealRes.data);
      }
    } catch (error) {
      setRejectLoading(false);
      setShowFailureRejectModal(true);
    }
  };

  // Validate creator service status
  const validateCreatorServiceStatus = async () => {
    const { isCommonConditionMet, newDeal } = await fetchAndCheckDealStatus(
      dealData._id,
      setDealData
    );

    if (isCommonConditionMet) {
      return;
    }

    if (newDeal?.creator_service_status === "validated") {
      setDealData(newDeal);
      return;
    }

    // Function to call the API for creator service validation
    const creatorApiCall = (dealId, walletAddress, hash) =>
      creatorApi.validateCreatorServiceStatus(dealId, hash);
    try {
      setValidateLoading(true);
      const validateServiceTx = DshareSC.methods.PatnerDealValidation(
        dealData.sc_id,
        1
      );

      await executeTransaction(
        validateServiceTx,
        dealData._id,
        walletConnected,
        creatorApiCall,
        process.env.REACT_APP_SCAN_API_KEY,
        setValidateLoading,
        setShowSuccessValidateModal,
        setDealData,
        setShowFailureValidateModal
      );
    } catch (error) {
      console.error("Error in validating service :", error);
      if (!error.message.includes("not mined within")) {
        setValidateLoading(false);
        setShowFailureValidateModal(true);
      }
    }
  };

  // Reject creator service status
  const rejectCreatorServiceStatus = async () => {
    const { isCommonConditionMet, newDeal } = await fetchAndCheckDealStatus(
      dealData._id,
      setDealData
    );

    if (isCommonConditionMet) {
      return;
    }

    if (
      newDeal?.creator_service_status === "validated" ||
      newDeal?.creator_service_status === "rejected"
    ) {
      setDealData(newDeal);
      return;
    }

    try {
      // nbr partner + 1 creator
      const nbrTotal = newDeal?.partners.length + 1;

      let nbrReject = newDeal?.partners.filter(
        (partner) => partner.serviceStatus === "rejected"
      ).length;

      if (newDeal?.creator_service_status === "rejected") nbrReject += 1;

      const isLastReject = nbrTotal - nbrReject === 1;

      if (isLastReject) {
        setShowCreatorRejectPopup(true);
        return;
      }

      setRejectLoading(true);

      // Make the API call and wait for the response
      const backResponseResult = await creatorApi.rejectCreatorServiceStatus(
        dealData._id
      );

      if (backResponseResult.status === 200) {
        setRejectLoading(false);
        setShowSuccessRejectModal(true);
        const dealRes = await dealApi.getDeal(dealData._id);
        setDealData(dealRes.data);
      }
    } catch (error) {
      setRejectLoading(false);
      setShowFailureRejectModal(true);
    }
  };

  const cancelDeal = async () => {
    setShowCancelConfirmationModal(false);
    await executeTransaction(
      DshareSC.methods.CancelDeal(dealData.sc_id),
      dealData._id,
      walletConnected,
      (dealId, walletAddress, hash) =>
        dealApi.cancelDeal(dealId, walletAddress, hash),
      process.env.REACT_APP_SCAN_API_KEY,
      setIsLoading,
      setShowSuccessModal,
      setDealData,
      setShowFailureModal
    );
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };
  const closeFailureModal = () => {
    setShowFailureModal(false);
  };

  const closeSuccessCallModal = () => {
    setShowSuccessCallModal(false);
  };
  const closeFailureCallModal = () => {
    setShowFailureCallModal(false);
  };
  const closeSuccessValidateModal = () => {
    setShowSuccessValidateModal(false);
  };
  const closeFailureValidateModal = () => {
    setShowFailureValidateModal(false);
  };
  // Added
  const closeSuccessRejectModal = () => {
    setShowSuccessRejectModal(false);
  };
  const closeFailureRejectModal = () => {
    setShowFailureRejectModal(false);
  };
  const closeSuccessUnlockedlModal = () => {
    setShowSuccessUnlockedModal(false);
  };
  const closeFailureUnlockedModal = () => {
    setShowFailureUnlockedModal(false);
  };

  // Parnter Calls the mediator
  const callMediator = async () => {
    const { isCommonConditionMet } = await fetchAndCheckDealStatus(
      dealData._id,
      setDealData
    );

    if (isCommonConditionMet) {
      return;
    }

    try {
      setIsLoading(true);
      setOpenPartnerConfirmation(false);

      const callMediatorTx = DshareSC.methods.PatnerDealValidation(
        dealData.sc_id,
        2
      );

      const callerId = deal?.partners?.find(
        (partner) =>
          partner.email === emailConnected ||
          partner.partnerId?.deals.find((d) => d.deal === dealData._id)
            ?.wallet === walletConnected
      )?.partnerId;

      // Utiliser executeTransaction pour gérer la transaction
      await executeTransaction(
        callMediatorTx,
        dealData._id,
        walletConnected,
        (dealId, walletAddress, hash) =>
          mediatorApi.callMediator(dealId, callerId, reason, hash),
        process.env.REACT_APP_SCAN_API_KEY,
        setIsLoading,
        setShowSuccessCallModal,
        setDealData,
        setShowFailureCallModal
      );
    } catch (error) {
      console.error("Error in calling mediator: ", error);
      setIsLoading(false);
      setShowFailureCallModal(true);
    }
  };

  // Creator Calls the mediator
  const creatorCallsMediator = async () => {
    const { isCommonConditionMet, newDeal } = await fetchAndCheckDealStatus(
      dealData._id,
      setDealData
    );

    if (isCommonConditionMet) {
      return;
    }

    try {
      setIsLoading(true);
      setOpenConfirmation(false);

      const callMediatorTx = DshareSC.methods.PatnerDealValidation(
        dealData.sc_id,
        2
      );

      // Utiliser executeTransaction pour gérer la transaction
      await executeTransaction(
        callMediatorTx,
        dealData._id,
        walletConnected,
        (dealId, walletAddress, hash) =>
          creatorApi.callMediator(dealId, deal?.creator, reason, hash),
        process.env.REACT_APP_SCAN_API_KEY,
        setIsLoading,
        setShowSuccessCallModal,
        setDealData,
        setShowFailureCallModal
      );
    } catch (error) {
      if (!error.message.includes("not mined within")) {
        setIsLoading(false);
        setShowFailureCallModal(true);
      }
    }
  };

  // handle UnlockFound
  const handleUnlockFund = async (dealId) => {
    let fundingWallet = "";

    if (dealData?.funding_wallet === dealData?.creator?.email)
      fundingWallet = dealData?.creator?.wallet;
    else {
      // Find the partner with the matching email in the populated partners array
      const partnerWithEmail = dealData.partners.find(
        (partner) => partner.email === dealData.funding_wallet
      );
      const matchingDeal = partnerWithEmail.partnerId.deals.find(
        (d) => d.deal === dealData._id
      );
      // Get the wallet associated with the matching deal
      const walletAssociated = matchingDeal.wallet;
      fundingWallet = walletAssociated;
    }

    try {
      const UnlockFundTx = DshareSC.methods.UnlockFund(dealData.sc_id);

      // Utiliser executeTransaction pour gérer la transaction
      await executeTransaction(
        UnlockFundTx,
        dealId,
        walletConnected,
        (dealId, walletAddress, hash) =>
          dealApi.unlockFunds(dealId, walletAddress, hash),
        process.env.REACT_APP_SCAN_API_KEY,
        setIsLoading,
        setShowSuccessUnlockedModal,
        setDealData,
        setShowFailureUnlockedModal
      );
    } catch (error) {
      console.error(error);
      if (!error.message.includes("not mined within")) {
        setIsLoading(false);
        setShowFailureUnlockedModal(true);
      }
    }
  };

  // Transform creator data structure
  const creatorData = [
    {
      id: deal?.dealId,
      values: [
        <div>
          <p className="font-bold text-xl">
            {dealData?.funding_wallet === dealData?.creator?.email
              ? "From:"
              : "To:"}
          </p>
          <div key={deal?.dealId}>
            <p>{deal?.creator?.email}</p>
            <a
              className="font-extralight underline underline-offset-2"
              href={`https://polygonscan.com/address/${dealData?.creator.wallet}`}
              target="_blank"
              rel="noreferrer"
            >
              {dealData?.creator.wallet.slice(0, 4)}...
              {dealData?.creator.wallet.slice(-4)}
            </a>
          </div>
        </div>,
        <div>
          <p className="font-bold">
            {dealData?.funding_wallet === dealData?.creator?.email
              ? "Share to pay"
              : "Share to receive"}
          </p>
          <p className="mt-6">{dealData?.creator.share}%</p>
        </div>,
        <div>
          <p className="font-bold">Gross amount</p>
          <p className="mt-6">{dealData?.creator.gross_amount}</p>
        </div>,
        <div>
          <p className="font-bold">Fee amount</p>
          <p className="mt-6">{dealData?.creator.fee_amount}</p>
        </div>,
        <div>
          <p className="font-bold">
            {dealData?.funding_wallet === dealData?.creator?.email
              ? "Net amount to pay"
              : "Net amount to receive"}
          </p>
          <p className="mt-6">{dealData?.creator.net_amount}</p>
        </div>,

        // deal status
        (dealData?.creator_deal_status === "pending" || !allMembersApproved) &&
        walletConnected === dealData?.creator.wallet &&
        dealData?.deal_status !== "rejected" &&
        dealData?.deal_status !== "canceled" &&
        dealData?.deal_status !== "outdated" ? (
          <div className="h-9 mt-6">
            <GenericButton
              text={
                isLoading ? (
                  <div className="w-10 h-10">
                    {" "}
                    <LoadingSpinner />
                  </div>
                ) : (
                  "Cancel deal"
                )
              }
              color="bg-[#D8E7E8]"
              onClick={async () => {
                const dealResponse = await dealApi.getDeal(dealData._id);
                const { deal_status } = dealResponse.data;
                if (deal_status === "outdated" || deal_status === "canceled") {
                  setDealData(dealResponse.data);
                  return;
                }

                setShowCancelConfirmationModal(true);
              }}
            />
          </div>
        ) : (dealData?.deal_status === "rejected" ||
            dealData?.deal_status === "outdated" ||
            dealData?.service_status === "rejected") &&
          !dealData.funds_unlocked &&
          dealData?.funding_wallet === dealData?.creator?.email &&
          dealData?.creator?.wallet === walletConnected ? (
          <div className="h-10 mt-6">
            <GenericButton
              text={
                isLoading ? (
                  <div className="w-10 h-10">
                    {" "}
                    <LoadingSpinner />
                  </div>
                ) : (
                  "Unlock funds"
                )
              }
              color="bg-[#D8E7E8]"
              onClick={() => handleUnlockFund(dealData?._id)}
            />
          </div>
        ) : dealData?.creator_deal_status === "pending" ||
          dealData?.creator_deal_status === "approved" ? (
          <p className="mt-12">Joined</p>
        ) : (
          <p className="mt-12">
            {dealData?.creator_deal_status?.charAt(0).toUpperCase() +
              dealData?.creator_deal_status?.slice(1)}
          </p>
        ),
        // service status
        dealData?.deal_status === "rejected" ||
        dealData?.deal_status === "canceled" ? (
          ""
        ) : // Disable validate button when service status is mediation
        dealData?.creator_service_status === "pending" &&
          dealData?.service_status === "mediation" &&
          walletConnected === dealData?.creator.wallet ? (
          <div className="mt-6">
            <GenericButton
              color="px-6 py-2 bg-gradient-to-r from-white to-button-pink opacity-50"
              text={
                validateLoading ? (
                  <div className="w-10 h-10">
                    {" "}
                    <LoadingSpinner />
                  </div>
                ) : (
                  "Validate"
                )
              }
            />
          </div>
        ) : (dealData?.creator_service_status === "pending" ||
            dealData?.creator_service_status === "rejected") &&
          dealData?.deal_status === "approved" &&
          dealData?.service_status === "pending" &&
          walletConnected === dealData?.creator.wallet ? (
          <div className="flex space-x-2 mt-6">
            <GenericButton
              color={`px-6 py-2 bg-gradient-to-r from-white to-button-pink ${
                (dealData?.deal_status === "outdated" || rejectLoading) &&
                "opacity-40 pointer-events-none disabled"
              }`}
              text={
                validateLoading ? (
                  <div className="w-6 h-6">
                    {" "}
                    <LoadingSpinner />
                  </div>
                ) : (
                  "Validate"
                )
              }
              onClick={() => validateCreatorServiceStatus()}
            />
            <GenericButton
              color={`px-6 py-2 bg-ice-blue ${
                (dealData?.creator_service_status === "rejected" ||
                  dealData?.deal_status === "outdated" ||
                  validateLoading) &&
                "opacity-40 pointer-events-none disabled"
              }`}
              text={
                rejectLoading ? (
                  <div className="w-6 h-6">
                    {" "}
                    <LoadingSpinner />
                  </div>
                ) : (
                  "Reject"
                )
              }
              onClick={() => rejectCreatorServiceStatus()}
            />
          </div>
        ) : dealData?.creator_service_status === "mediation" ? (
          <p className="text-red-400 pt-12"> Called the arbitrator</p>
        ) : (
          <p className="mt-12">
            {dealData?.creator_service_status?.charAt(0).toUpperCase() +
              dealData?.creator_service_status?.slice(1)}
          </p>
        ),
      ],
    },
  ];

  // Transform partners data structure
  const PartnersNotFWdata = dealData?.partners
    ?.filter((item) => item.email !== dealData?.funding_wallet)
    .map((item) => {
      return {
        id: item.dealId,
        values: [
          <div>
            <p className="font-bold text-xl">
              {item.email === dealData?.funding_wallet ? "From:" : "To:"}
            </p>
            <div key={item._id}>
              <p> {item.email} </p>
              {item.partnerId?.deals.find((d) => d.deal === dealData._id)
                ?.wallet && (
                <a
                  className="font-extralight underline underline-offset-2"
                  href={`https://polygonscan.com/address/${
                    item.partnerId?.deals.find((d) => d.deal === dealData._id)
                      ?.wallet
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.partnerId?.deals
                    .find((d) => d.deal === dealData._id)
                    ?.wallet?.slice(0, 4)}
                  ...
                  {item.partnerId?.deals
                    .find((d) => d.deal === dealData._id)
                    ?.wallet?.slice(0 - 4)}
                </a>
              )}
            </div>
          </div>,
          <div>
            <p className="font-bold mb-6">
              {item.email === dealData?.funding_wallet
                ? "Share to pay"
                : "Share to receive"}
            </p>
            <p>{item.share_percentage}%</p>
          </div>,
          <div>
            <p className="font-bold mb-6">Gross amount</p>
            <p>{item.gross_amount}</p>
          </div>,
          <div>
            <p className="font-bold mb-6">Fee amount</p>
            <p>{item.fees_amount}</p>
          </div>,
          <div>
            <p className="font-bold mb-6">
              {item.email === dealData?.funding_wallet
                ? "Net amount to pay"
                : "Net amount to receive"}
            </p>
            <p>{item.net_amount}</p>
          </div>,

          item.dealStatus === "pending" &&
          item.email === emailConnected &&
          !(
            dealData?.creator_deal_status === "pending" &&
            walletConnected === dealData?.creator.wallet &&
            dealData?.deal_status !== "rejected"
          ) ? (
            <div className="mt-6">
              <ApprovalButton
                deal={dealData}
                setDealData={setDealData}
                partnerId={item.partnerId._id}
                isFundingWallet={item.email === dealData?.funding_wallet}
                partnerEmail={item.email}
                isMediator={isMediator}
                onDealRejection={handleDealRejection}
                setWalletWarning={setWalletWarning}
              />
            </div>
          ) : ((item.dealStatus === "approved" &&
              (dealData?.deal_status === "rejected" ||
                dealData?.deal_status === "outdated" ||
                dealData?.service_status === "rejected")) ||
              dealData?.deal_status === "canceled") &&
            !dealData.funds_unlocked &&
            item.partnerId?.deals.find((d) => d.deal === dealData?._id)
              ?.wallet === walletConnected &&
            item.email === dealData?.funding_wallet ? (
            <div className="h-10 mt-6">
              <GenericButton
                text={
                  isLoading ? (
                    <div className="w-10 h-10">
                      {" "}
                      <LoadingSpinner />
                    </div>
                  ) : (
                    "Unlock funds"
                  )
                }
                color="bg-[#D8E7E8]"
                onClick={() => handleUnlockFund(dealData?._id)}
              />
            </div>
          ) : item.dealStatus === "approved" ? (
            <p className="mt-12">Joined</p>
          ) : (
            <p className="mt-12">
              {item.dealStatus?.charAt(0).toUpperCase() +
                item.dealStatus?.slice(1)}
            </p>
          ),
          // Service Status
          item.serviceStatus === "mediation" ? (
            <p className="text-red-400 pt-12"> Called the arbitrator</p>
          ) : dealData?.deal_status === "approved" &&
            dealData?.service_status === "pending" &&
            (item.serviceStatus === "pending" ||
              item.serviceStatus === "rejected") &&
            item.partnerId?.deals.find((d) => d.deal === dealData._id)
              ?.wallet === walletConnected ? (
            <div className="flex space-x-2 mt-6">
              <GenericButton
                color={`px-6 py-2 bg-gradient-to-r from-white to-button-pink ${
                  (dealData?.deal_status === "outdated" || rejectLoading) &&
                  "opacity-40 pointer-events-none disabled"
                }`}
                text={
                  validateLoading ? (
                    <div className="w-6 h-6">
                      {" "}
                      <LoadingSpinner />
                    </div>
                  ) : (
                    "Validate"
                  )
                }
                onClick={() => validatePartnerServiceStatus(item.partnerId._id)}
              />
              <GenericButton
                color={`px-6 py-2 bg-ice-blue ${
                  (item.serviceStatus === "rejected" ||
                    dealData?.deal_status === "outdated" ||
                    validateLoading) &&
                  "opacity-40 pointer-events-none disabled"
                }`}
                text={
                  rejectLoading ? (
                    <div className="w-6 h-6">
                      {" "}
                      <LoadingSpinner />
                    </div>
                  ) : (
                    "Reject"
                  )
                }
                onClick={() => rejectPartnerServiceStatus(item.partnerId._id)}
              />
            </div>
          ) : item.serviceStatus === "pending" &&
            dealData?.service_status === "mediation" &&
            (item.email === emailConnected ||
              item.partnerId?.deals.find((d) => d.deal === dealData._id)
                ?.wallet === walletConnected) ? (
            <div className="mt-6">
              <GenericButton
                color="px-6 py-2 bg-gradient-to-r from-white to-button-pink opacity-50"
                text="Validate"
              />
            </div>
          ) : dealData?.deal_status === "rejected" ||
            dealData?.deal_status === "canceled" ? (
            ""
          ) : (
            <p className="mt-12">
              {item.serviceStatus?.charAt(0).toUpperCase() +
                item.serviceStatus?.slice(1)}
            </p>
          ),
        ],
      };
    });

  // Transform partner FUNDING WALLET structure
  const PartnerFWdata = dealData?.partners
    ?.filter((item) => item.email === dealData?.funding_wallet)
    ?.map((item) => {
      return {
        id: item.dealId,
        values: [
          <div>
            <p className="font-bold text-xl">
              {item.email === dealData?.funding_wallet ? "From:" : "To:"}
            </p>
            <div key={item._id}>
              <p> {item.email} </p>
              {item.partnerId?.deals.find((d) => d.deal === dealData._id)
                ?.wallet && (
                <a
                  className="font-extralight underline underline-offset-2"
                  href={`https://polygonscan.com/address/${
                    item.partnerId?.deals.find((d) => d.deal === dealData._id)
                      ?.wallet
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.partnerId?.deals
                    .find((d) => d.deal === dealData._id)
                    ?.wallet?.slice(0, 4)}
                  ...
                  {item.partnerId?.deals
                    .find((d) => d.deal === dealData._id)
                    ?.wallet?.slice(0 - 4)}
                </a>
              )}
            </div>
          </div>,
          <div>
            <p className="font-bold mb-6">
              {item.email === dealData?.funding_wallet
                ? "Share to pay"
                : "Share to receive"}
            </p>
            <p>{item.share_percentage}%</p>
          </div>,
          <div>
            <p className="font-bold mb-6">Gross amount</p>
            <p>{item.gross_amount}</p>
          </div>,
          <div>
            <p className="font-bold mb-6">Fee amount</p>
            <p>{item.fees_amount}</p>
          </div>,
          <div>
            <p className="font-bold mb-6">
              {item.email === dealData?.funding_wallet
                ? "Net amount to pay"
                : "Net amount to receive"}
            </p>
            <p>{item.net_amount}</p>
          </div>,

          item.dealStatus === "pending" &&
          item.email === emailConnected &&
          !(
            dealData?.creator_deal_status === "pending" &&
            walletConnected === dealData?.creator.wallet &&
            dealData?.deal_status !== "rejected"
          ) ? (
            <div className="mt-6">
              <ApprovalButton
                deal={dealData}
                setDealData={setDealData}
                partnerId={item.partnerId._id}
                isFundingWallet={item.email === dealData?.funding_wallet}
                partnerEmail={item.email}
                isMediator={isMediator}
                onDealRejection={handleDealRejection}
                setWalletWarning={setWalletWarning}
              />
            </div>
          ) : ((item.dealStatus === "approved" &&
              (dealData?.deal_status === "rejected" ||
                dealData?.deal_status === "outdated" ||
                dealData?.service_status === "rejected")) ||
              dealData?.deal_status === "canceled") &&
            !dealData.funds_unlocked &&
            item.partnerId?.deals.find((d) => d.deal === dealData?._id)
              ?.wallet === walletConnected &&
            item.email === dealData?.funding_wallet ? (
            <div className="h-10 mt-6">
              <GenericButton
                text={
                  isLoading ? (
                    <div className="w-10 h-10">
                      {" "}
                      <LoadingSpinner />
                    </div>
                  ) : (
                    "Unlock funds"
                  )
                }
                color="bg-[#D8E7E8]"
                onClick={() => handleUnlockFund(dealData?._id)}
              />
            </div>
          ) : item.dealStatus === "approved" ? (
            <p className="mt-12">Joined</p>
          ) : (
            <p className="mt-12">
              {item.dealStatus?.charAt(0).toUpperCase() +
                item.dealStatus?.slice(1)}
            </p>
          ),
          // Service Status
          item.serviceStatus === "mediation" ? (
            <p className="text-red-400 pt-12"> Called the arbitrator</p>
          ) : dealData?.deal_status === "approved" &&
            dealData?.service_status === "pending" &&
            (item.serviceStatus === "pending" ||
              item.serviceStatus === "rejected") &&
            item.partnerId?.deals.find((d) => d.deal === dealData._id)
              ?.wallet === walletConnected ? (
            <div className="flex space-x-2 mt-6">
              <GenericButton
                color={`px-6 py-2 bg-gradient-to-r from-white to-button-pink ${
                  (dealData?.deal_status === "outdated" || rejectLoading) &&
                  "opacity-40 pointer-events-none disabled"
                }`}
                text={
                  validateLoading ? (
                    <div className="w-6 h-6">
                      {" "}
                      <LoadingSpinner />
                    </div>
                  ) : (
                    "Validate"
                  )
                }
                onClick={() => validatePartnerServiceStatus(item.partnerId._id)}
              />
              <GenericButton
                color={`px-6 py-2 bg-ice-blue ${
                  (item.serviceStatus === "rejected" ||
                    dealData?.deal_status === "outdated" ||
                    validateLoading) &&
                  "opacity-40 pointer-events-none disabled"
                }`}
                text={
                  rejectLoading ? (
                    <div className="w-6 h-6">
                      {" "}
                      <LoadingSpinner />
                    </div>
                  ) : (
                    "Reject"
                  )
                }
                onClick={() => rejectPartnerServiceStatus(item.partnerId._id)}
              />
            </div>
          ) : item.serviceStatus === "pending" &&
            dealData?.service_status === "mediation" &&
            (item.email === emailConnected ||
              item.partnerId?.deals.find((d) => d.deal === dealData._id)
                ?.wallet === walletConnected) ? (
            <div className="mt-6">
              <GenericButton
                color="px-6 py-2 bg-gradient-to-r from-white to-button-pink opacity-50"
                text="Validate"
              />
            </div>
          ) : dealData?.deal_status === "rejected" ||
            dealData?.deal_status === "canceled" ? (
            ""
          ) : (
            <p className="mt-12">
              {item.serviceStatus?.charAt(0).toUpperCase() +
                item.serviceStatus?.slice(1)}
            </p>
          ),
        ],
      };
    });

  // Transform mediator data structure
  const MediatorData = [
    {
      id: dealData?.dealId,
      values: [
        <div>
          <p className="font-bold text-xl">Arbitrator</p>
          <div key={dealData?.dealId} className="flex flex-col">
            <p>{dealData?.mediator?.email}</p>

            <a
              className="font-extralight underline underline-offset-2"
              href={`https://polygonscan.com/address/${dealData?.mediator?.wallet}`}
              target="_blank"
              rel="noreferrer"
            >
              {dealData?.mediator?.wallet.slice(0, 4)}...
              {dealData?.mediator?.wallet.slice(-4)}
            </a>
          </div>
        </div>,
        // Commission
        <div className="">
          <p className="font-bold">Indicative Commission</p>
          <p className="mt-6">{dealData?.mediator_commission_percentage}%</p>
        </div>,
        "",
        "",
        "",

        // Mediator deal Status
        dealData?.mediator_deal_status === "pending" &&
        (isMediator || dealData?.mediator?.wallet === walletConnected) ? (
          <div className="mt-6">
            <ApprovalButton
              deal={dealData}
              setDealData={setDealData}
              partnerId={null}
              isFundingWallet={null}
              partnerEmail={null}
              isMediator={isMediator}
              onDealRejection={handleDealRejection}
              setWalletWarning={setWalletWarning}
            />
          </div>
        ) : dealData?.mediator_deal_status === "approved" ? (
          <p className="mt-12">Joined</p>
        ) : (
          <p className="mt-12">
            {dealData?.mediator_deal_status?.charAt(0).toUpperCase() +
              dealData?.mediator_deal_status?.slice(1)}
          </p>
        ),
        /* Only partner can call the mediator */
        dealData?.service_status === "mediation" ? (
          <div className="mt-6">
            <GenericButton
              color="px-6 py-2 bg-gradient-to-r from-[#F2F3D0] to-[#EE0000] opacity-50"
              text="Called..."
            />
          </div>
        ) : dealData?.partners?.some(
            (partner) =>
              partner.partnerId?.deals.find((d) => d.deal === dealData._id)
                ?.wallet === walletConnected
          ) &&
          dealData?.deal_status === "approved" &&
          dealData?.service_status === "pending" &&
          // Partner that not validated the service
          (dealData?.partners?.find(
            (partner) =>
              partner.partnerId?.deals.find((d) => d.deal === dealData._id)
                ?.wallet === walletConnected
          ).serviceStatus !== "validated" ||
            // Partner that validated the service & global service status not validated by all
            dealData?.partners?.find(
              (partner) =>
                partner.partnerId?.deals.find((d) => d.deal === dealData._id)
                  ?.wallet === walletConnected
            ).serviceStatus === "validated") ? (
          <GenericButton
            color={`px-6 py-2 mt-6 bg-gradient-to-r from-[#F2F3D0] to-[#EE0000] ${
              (validateLoading || rejectLoading) &&
              "opacity-40 pointer-events-none disabled"
            } `}
            text={
              isLoading ? (
                <div className="w-10 h-10">
                  {" "}
                  <LoadingSpinner />
                </div>
              ) : (
                "Call Arbitrator!"
              )
            }
            onClick={async () => {
              const dealResponse = await dealApi.getDeal(dealData._id);
              const { service_status } = dealResponse.data;

              if (
                service_status === "rejected" ||
                service_status === "outdated"
              ) {
                setDealData(dealResponse.data);
                return;
              }

              setOpenCallPartnerReason(true);
            }}
          />
        ) : (
          // Creator called the mediator
          walletConnected === dealData?.creator?.wallet &&
          dealData?.deal_status === "approved" &&
          dealData?.service_status === "pending" &&
          (dealData.creator_service_status !== "validated" ||
            dealData.creator_service_status === "validated") && (
            <GenericButton
              color={`px-6 py-2 mt-6 bg-gradient-to-r from-[#F2F3D0] to-[#EE0000] ${
                (validateLoading || rejectLoading) &&
                "opacity-40 pointer-events-none disabled"
              }`}
              text={
                isLoading ? (
                  <div className="w-10 h-10">
                    {" "}
                    <LoadingSpinner />
                  </div>
                ) : (
                  "Call Arbitrator!"
                )
              }
              onClick={async () => {
                const dealResponse = await dealApi.getDeal(dealData._id);
                const { service_status } = dealResponse.data;

                if (
                  service_status === "rejected" ||
                  service_status === "outdated"
                ) {
                  setDealData(dealResponse.data);
                  return;
                }
                setOpenCallReason(true);
              }}
            />
          )
        ),
      ],
    },
  ];

  return (
    <table
      className={`${marginLeftClass} rounded-lg outline outline-offset-0 outline-4 outline-ice-blue`}
    >
      <thead className="rounded-lg  w-full h-[140px] px-4  bg-gradient-to-b from-from-deep-navy to-deep-navy text-white text-left">
        <tr className="">
          <th className="px-5" style={{ whiteSpace: "pre-line" }}>
            <p
              className={`mb-2 text-2xl ${
                dealData?.name.length < 19 ? "w-[250px]" : "w-[300px]"
              }`}
            >
              {dealData?.name}
            </p>
            <div className="flex items-center">
              <p className="text-xl font-light">
                {dealData?.gross_amount} USDT
              </p>
              <div className="ml-2 bg-purple-200 rounded-full w-6 h-6 flex items-center justify-center">
                <img
                  src="https://cryptologos.cc/logos/polygon-matic-logo.svg?v=031"
                  alt="Polygon Matic Logo"
                  className="w-4"
                />
              </div>
            </div>
          </th>
          {/* Deadline */}
          <th className="px-5">
            <p className="text-xl mb-2">Service Deadline</p>
            <p className="text-base">
              {dealData?.deadline.split("T")[0].split("-").reverse().join("/")}
            </p>
          </th>
          {/* Partners */}
          <th className="px-5">
            <p className="text-xl mb-2">Partners</p>
            <p className="text-base">{numberPartners}</p>
          </th>
          {/* Protocol Fee */}
          <th className="px-5">
            <p className="text-xl mb-2">Protocol Fee</p>
            <p className="text-base">{dealData?.fees_amount}</p>
          </th>
          {/* Net Amount */}
          <th className="px-5">
            <p className="text-xl mb-2 w-[120px]">Net Amount</p>
            <p className="text-base">{dealData?.net_amount}</p>
          </th>
          {/* Deal Status */}
          <th className="px-5">
            <p className="text-xl mb-2 w-[120px]">Deal Status</p>
            <p
              className={`text-base ${
                (dealData?.deal_status === "rejected" ||
                  dealData?.deal_status === "canceled" ||
                  deal?.deal_status === "outdated") &&
                "text-red-500"
              }`}
            >
              {dealData?.deal_status?.charAt(0).toUpperCase() +
                dealData?.deal_status?.slice(1)}
            </p>
          </th>
          {/* Service Status */}
          <th className="px-5">
            <p
              className={`text-xl  w-[140px] ${
                dealData?.deal_status === "rejected" ||
                dealData?.deal_status === "canceled" ||
                deal?.deal_status === "outdated"
                  ? "mb-8"
                  : "mb-2"
              }`}
            >
              Service Status
            </p>
            <p className="text-base">
              {dealData?.deal_status === "rejected" ||
              dealData?.deal_status === "canceled" ||
              deal?.deal_status === "outdated"
                ? ""
                : dealData?.service_status === "mediation"
                ? "Arbitration"
                : dealData?.service_status?.charAt(0).toUpperCase() +
                  dealData?.service_status?.slice(1)}
            </p>
          </th>
        </tr>
      </thead>
      <tbody className="text-white">
        {/* Funding wallet DATA (creator or partner) */}
        {dealData?.funding_wallet === dealData?.creator?.email
          ? creatorData?.map((item) => (
              <TableRow
                key={item.id}
                rowData={item.values}
                color="bg-steel-blue border-b border-white"
              />
            ))
          : PartnerFWdata?.map((item) => (
              <TableRow
                key={item.id}
                rowData={item.values}
                color="bg-steel-blue border-b border-white"
              />
            ))}
        {/* Creator DATA not funding wallet */}
        {dealData?.funding_wallet !== dealData?.creator?.email &&
          creatorData?.map((item) => (
            <TableRow
              key={item.id}
              rowData={item.values}
              color="bg-steel-blue"
            />
          ))}

        {/* Partners DATA not funding wallet */}
        {PartnersNotFWdata?.map((item) => (
          <TableRow key={item.id} rowData={item.values} color="bg-steel-blue" />
        ))}

        {/* Mediator DATA */}
        {dealData?.mediator?.email &&
          MediatorData?.map((item) => (
            <TableRow
              key={item.id}
              rowData={item.values}
              color="bg-[#31818B]"
            />
          ))}
        {/* Validation Method */}
        <div className="flex justify-center items-center pt-4">
          <p className="font-bold text-xl p-4">Validation Method:</p>
          <p className="font-bold">Partners Signature</p>
        </div>
        {/* Description if service status is mediation */}
        <div className="m-4">
          <p className="text-sm">
            {dealData?.service_status === "mediation" &&
              "The money has been transferred to the arbitrator."}
          </p>

          <p className="text-sm">
            {dealData?.service_status === "mediation" &&
              "He is responsible for the next steps."}
          </p>
        </div>
      </tbody>
      {showFailureModal && (
        <FailureModal
          title="Oops!"
          text="An error has occurred while canceling the deal"
          closeModal={closeFailureModal}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          title="Deal Canceled successfully!"
          text="your deal has been canceled successfully"
          closeModal={closeSuccessModal}
          onButtonClick={closeSuccessModal}
        />
      )}
      {showCancelConfirmationModal && (
        <GenericModal
          closeModal={() => setShowCancelConfirmationModal(false)}
          title="Are you sure you want to cancel the deal?"
          buttonColor="bg-gradient-to-r from-white to-button-pink"
          buttonText="Yes, Cancel!"
          onButtonClick={cancelDeal}
        >
          <p>
            As long as none of the partners has accepted the deal, you can
            cancel it at any time.
          </p>
        </GenericModal>
      )}
      {/* Partner calls the mediator */}
      {openCallPartnerReason && (
        <GenericModal
          closeModal={() => setOpenCallPartnerReason(false)}
          title="Explain why you call the arbitrator:"
          buttonColor="bg-gradient-to-r from-white to-button-pink"
          buttonText="Next"
          onButtonClick={() => {
            setGoConfirmation(true);
            setOpenPartnerConfirmation(true);
          }}
        >
          <textarea
            name=""
            id=""
            cols="30"
            rows="4"
            placeholder="Explain in a few sentences..."
            className="text-center pt-14 bg-[#17202C] border border-ice-blue rounded-xl"
            value={reason}
            onChange={handleInputChange}
          />
        </GenericModal>
      )}
      {/* Creator calls the mediator */}
      {openCallReason && (
        <GenericModal
          closeModal={() => setOpenCallReason(false)}
          title="Explain why you call the arbitrator:"
          buttonColor="bg-gradient-to-r from-white to-button-pink"
          buttonText="Next"
          onButtonClick={() => {
            setGoConfirmation(true);
            setOpenConfirmation(true);
          }}
        >
          <textarea
            name=""
            id=""
            cols="30"
            rows="4"
            placeholder="Explain in a few sentences..."
            className="text-center pt-14 bg-[#17202C] border border-ice-blue rounded-xl"
            value={reason}
            onChange={handleInputChange}
          />
        </GenericModal>
      )}
      {/* Partner calls the mediator */}
      {goConfirmation && openPartnerConfirmation && (
        <GenericModal
          closeModal={() => setOpenPartnerConfirmation(false)}
          title="Are you sure you want to call the arbitrator?"
          buttonColor="bg-gradient-to-r from-[#F2F3D0] to-[#EE0000]"
          buttonText="I am sure!"
          // onButtonClick={() => {
          //   setGoConfirmation(true);
          // }}
          onButtonClick={callMediator}
        >
          <p className="text-center">
            The money will be transferred to the arbitrator wallet and he will
            be responsible for the next steps outside of DSHARE.
          </p>
        </GenericModal>
      )}

      {goConfirmation && openConfirmation && (
        <GenericModal
          closeModal={() => setOpenConfirmation(false)}
          title="Are you sure you want to call the arbitrator?"
          buttonColor="bg-gradient-to-r from-[#F2F3D0] to-[#EE0000]"
          buttonText="I am sure!"
          onButtonClick={creatorCallsMediator}
        >
          <p className="text-center">
            The money will be transferred to the arbitrator wallet and he will
            be responsible for the next steps outside of DSHARE.
          </p>
        </GenericModal>
      )}

      {showSuccessCallModal && (
        <SuccessModal
          title="Arbitrator called successfully!"
          text=""
          closeModal={closeSuccessCallModal}
          onButtonClick={closeSuccessCallModal}
        />
      )}
      {showFailureCallModal && (
        <FailureModal
          title="Oops!"
          text="An error has occurred while calling the arbitrator"
          closeModal={closeFailureCallModal}
        />
      )}
      {showSuccessValidateModal && (
        <SuccessModal
          title="Service validated successfully!"
          text=""
          closeModal={closeSuccessValidateModal}
          onButtonClick={closeSuccessValidateModal}
        />
      )}
      {showFailureValidateModal && (
        <FailureModal
          title="Oops!"
          text="An error has occurred while validating the service"
          closeModal={closeFailureValidateModal}
        />
      )}
      {showSuccessRejectModal && (
        <SuccessModal
          title="Service rejected successfully!"
          text=""
          closeModal={closeSuccessRejectModal}
          onButtonClick={closeSuccessRejectModal}
        />
      )}
      {showFailureRejectModal && (
        <FailureModal
          title="Oops!"
          text="An error has occurred while rejecting the service"
          closeModal={closeFailureRejectModal}
        />
      )}
      {showSuccessUnlockedModal && (
        <SuccessModal
          title="Funds unlocked successfully!"
          text=""
          closeModal={closeSuccessUnlockedlModal}
          onButtonClick={closeSuccessUnlockedlModal}
        />
      )}
      {showFailureUnlockedModal && (
        <FailureModal
          title="Oops!"
          text="An error has occurred while unlocking funds"
          closeModal={closeFailureUnlockedModal}
        />
      )}
      {walletWarning && (
        <WarningModal
          title="Wallet exists ! "
          text="This wallet is already use by another party of this deal "
          setShowWarning={setWalletWarning}
        />
      )}
      {showRejectPopup && (
        <GenericModal
          closeModal={() => setShowRejectPopup(false)}
          title="Are you sure you want to reject the service?"
          buttonColor="bg-gradient-to-r from-[#F2F3D0] to-[#EE0000]"
          buttonText="I am sure!"
          onButtonClick={async () => {
            setRejectLoading(true);

            // Make the API call and wait for the response
            if (refIdPartner.current) {
              const backResponseResult =
                await partnerApi.rejectPartnerServiceStatus(
                  dealData._id,
                  refIdPartner.current,
                  true
                );

              // appel api update global state
              if (backResponseResult.status === 200) {
                setRejectLoading(false);
                setShowSuccessRejectModal(true);
                const dealRes = await dealApi.getDeal(dealData._id);
                setDealData(dealRes.data);
                setShowRejectPopup(false);
              }

              refIdPartner.current = null;
            }
          }}
        >
          <p className="text-center">
            Your rejection will cancel the entire deal service.
          </p>
        </GenericModal>
      )}
      {/* Creator is the last one who rejected the service */}
      {showCreatorRejectPopup && (
        <GenericModal
          closeModal={() => setShowCreatorRejectPopup(false)}
          title="Are you sure you want to reject the service?"
          buttonColor="bg-gradient-to-r from-[#F2F3D0] to-[#EE0000]"
          buttonText="I am sure!"
          onButtonClick={async () => {
            setRejectLoading(true);

            // Make the API call and wait for the response

            const backResponseResult =
              await creatorApi.rejectCreatorServiceStatus(dealData._id, true);

            // appel api update global state
            if (backResponseResult.status === 200) {
              setRejectLoading(false);
              setShowSuccessRejectModal(true);
              const dealRes = await dealApi.getDeal(dealData._id);
              setDealData(dealRes.data);
              setShowCreatorRejectPopup(false);
            }
          }}
        >
          <p className="text-center">
            Your rejection will cancel the entire deal service.
          </p>
        </GenericModal>
      )}
      {/* NotFundingModal appears when transaction is loading */}
      {(isLoading || validateLoading) && <NotFundingModal />}
    </table>
  );
}

export default DealDetailsTable;
