/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import CancelIcon from "../../../assets/icons/cancel.svg";
// import LoadingSpinner from "../../LoadingSpinner";

function StepFour({
  dealData,
  setDealData,
  setIndicator,
  // handleSubmit,
  // isLoading,
}) {
  const [mediatorData, setMediatorData] = useState({
    wallet: "",
    email: "",
    commissionPercentage: "",
    netAmount: "",
  });

  const [mediatorAdded, setMediatorAdded] = useState(false);
  // Length of mediator (existence of mediator)
  const mediatorLength = Object.keys(dealData.mediator).length;

  // Form errors
  const [formErrors, setFormErrors] = useState({});

  // Function to calculate commissionPercentage based on netAmount
  const calculateCommissionPercentage = (netAmount) => {
    if (!Number.isNaN(netAmount) && netAmount !== "0") {
      const commissionPercentage =
        (parseFloat(netAmount) / parseFloat(dealData.dealAmount)) * 100;
      return parseFloat(commissionPercentage.toFixed(2));
    }
    return "";
  };

  // Function to calculate netAmount based on commissionPercentage
  const calculateNetAmount = (commissionPercentage) => {
    if (!Number.isNaN(commissionPercentage) && commissionPercentage !== "0") {
      const netAmount =
        (commissionPercentage * parseFloat(dealData.netAmount)) / 100;
      return parseFloat(netAmount.toFixed(2));
    }
    return "";
  };

  // Form Error handling
  const validate = (values) => {
    const errors = {};
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
    if (!values.email) {
      errors.email = "Enter the mediator's email";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Enter a valid email address";
    } else if (values.email === dealData.creatorEmail) {
      errors.email = "Choose a different email than the creator's email";
    }

    // Wallet validation
    const walletRegex = /^0x[a-fA-F0-9]{40}$/;
    if (!values.wallet) {
      errors.wallet = "Enter a wallet address";
    } else if (!walletRegex.test(values.wallet)) {
      errors.wallet = "Enter a valid wallet";
    } else if (
      values.wallet.toLowerCase() === dealData.creatorWallet.toLowerCase()
    ) {
      errors.wallet = "Choose a different wallet than the creator's wallet";
    }

    // commissionPercentage validation
    if (!values.commissionPercentage) {
      errors.commissionPercentage = "Enter a commission %";
    } else if (Number.isNaN(Number(values.commissionPercentage))) {
      errors.commissionPercentage = "Enter a valid number for the commission %";
    } else if (Number(values.commissionPercentage) > 100) {
      errors.commissionPercentage = "The percentage must not exceed 100";
    } else if (Number(values.commissionPercentage) < 0) {
      errors.commissionPercentage = "The percentage must be positive";
    }

    // netAmount validation
    if (!values.netAmount) {
      errors.netAmount = "Enter a netAmount";
    } else if (Number.isNaN(Number(values.netAmount))) {
      errors.netAmount = "Enter a valid number for the netAmount";
    } else if (Number(values.netAmount) < 0) {
      errors.netAmount = "The netAmount must be positive";
    }

    return errors;
  };

  const handleConfirm = () => {
    const errors = validate(mediatorData);

    if (Object.keys(errors).length === 0) {
      setDealData({
        ...dealData,
        mediator: {
          ...mediatorData,
        },
      });

      setMediatorData({
        wallet: "",
        email: "",
        commissionPercentage: "",
        netAmount: "",
      });

      setFormErrors({});
      setMediatorAdded(true);
    } else {
      setFormErrors(errors);
    }
  };

  const handleMediatorInputChange = (e) => {
    const { name, value } = e.target;
    setMediatorData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "netAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          netAmount: "Please enter a valid number for the netAmount",
        }));
        setMediatorData((prev) => ({
          ...prev,
          commissionPercentage: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          netAmount: "",
          commissionPercentage: "",
        }));
        setMediatorData((prev) => {
          const com = calculateCommissionPercentage(value);
          return {
            ...prev,
            commissionPercentage: com,
          };
        });
      }
    }

    if (name === "commissionPercentage") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          commissionPercentage: "Please enter a valid number for the netAmount",
        }));
        setMediatorData((prev) => ({
          ...prev,
          netAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          commissionPercentage: "",
          netAmount: "",
        }));

        setMediatorData((prev) => {
          const net = calculateNetAmount(value);
          return {
            ...prev,
            netAmount: net,
          };
        });
      }
    }
  };

  const handleDeleteMediator = () => {
    setDealData({
      ...dealData,
      mediator: {}, // Remove the mediator data
    });

    setMediatorAdded(false);
  };

  return (
    <div className="">
      <div className="m-5 text-3xl flex">
        <p className="font-bold">Arbitration</p>
        <p className="ml-2 font-light	">(Optional)</p>
      </div>
      <button
        type="button"
        className="relative mx-5 mb-5 w-[360px] h-8 rounded-full text-center font-bold"
        onClick={() => {}}
      >
        <div
          className={`absolute left-0 top-0 w-1/2 h-full rounded-l-full ${
            dealData.fees ? "bg-ice-blue text-shadow-blue" : "bg-shadow-blue"
          } transition flex items-center justify-center`}
        >
          Add my arbitrator
        </div>
        <div
          className={`absolute right-0 top-0 w-1/2 h-full rounded-r-full border-[1px] border-solid border-[#00e2ff] ${
            dealData.fees ? "bg-shadow-blue" : "bg-ice-blue text-shadow-blue"
          } transition flex items-center justify-center`}
        >
          Select from list
        </div>
      </button>

      <div className="flex flex-row justify-around text-sm mb-2">
        <p className="w-[300px] ml-2 px-4 flex items-center justify-center">
          Arbitrator Email Address
        </p>
        <p className="w-[300px] flex items-center justify-center">
          Arbitrator Wallet Address
        </p>
        <p className="w-[200px] flex items-center justify-center">
          Commission % on net amount
        </p>
        <p className="w-[140px] mr-40 flex items-center justify-center">
          Net amount
        </p>
      </div>
      <div className="flex justify-between mb-5 mx-5">
        {/* Mediator Email address */}
        <div className="flex flex-col">
          <input
            className="mr-4 px-4 w-[300px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
            type="text"
            name="email"
            value={mediatorData.email}
            onChange={handleMediatorInputChange}
            placeholder="Arbitrator Email Address"
          />
          {formErrors.email && (
            <p className="text-red-600 w-[300px]">{formErrors.email}</p>
          )}
        </div>
        {/* Mediator wallet address */}
        <div className="flex flex-col">
          <input
            className="mr-4 px-4 w-[300px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
            type="text"
            name="wallet"
            value={mediatorData.wallet}
            onChange={handleMediatorInputChange}
            placeholder="Arbitrator Wallet Address"
          />
          {formErrors.wallet && (
            <p className="text-red-600 w-[300px]">{formErrors.wallet}</p>
          )}
        </div>
        {/* Commission percentage */}
        <div className="flex flex-col">
          <input
            className="mr-4 px-4 w-[200px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
            type="text"
            name="commissionPercentage"
            value={mediatorData.commissionPercentage}
            onChange={handleMediatorInputChange}
            placeholder="Commission % on net amount"
          />
          {formErrors.commissionPercentage && (
            <p className="text-red-600 w-[200px]">
              {formErrors.commissionPercentage}
            </p>
          )}
        </div>
        {/* Net Amount */}
        <div className="flex flex-col">
          <input
            className="mr-4 px-4 w-[140px] h-12 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
            type="text"
            name="netAmount"
            value={mediatorData.netAmount}
            onChange={handleMediatorInputChange}
            placeholder="Net Amount"
          />
          {formErrors.netAmount && (
            <p className="text-red-600 w-[140px]">{formErrors.netAmount}</p>
          )}
        </div>
        {/* Add Button */}
        <button
          className={`w-[137px] h-12 text-[#10131c] text-base font-bold bg-gradient-to-t from-[#ee00e5] to-[#f2f3d0] rounded-full ${
            mediatorAdded && "opacity-50"
          } `}
          type="button"
          onClick={handleConfirm}
          disabled={mediatorAdded}
        >
          Confirm
        </button>
      </div>
      {/* Display mediator data */}
      {Object.keys(dealData.mediator).length > 0 && (
        <div className="flex justify-between bg-steel-blue mb-5 mx-5 h-16">
          <p className="mr-4 px-4 w-[300px] flex items-center justify-center">
            {dealData.mediator.email}
          </p>
          <p className=" flex items-center justify-center text-xs mr-4 w-[300px]">
            {dealData.mediator.wallet}
          </p>
          <p className="mr-4 px-4 w-[200px] flex items-center justify-center font-bold">
            {`${dealData.mediator.commissionPercentage}%`}
          </p>
          <p className="mr-4 px-4 w-[140px] flex items-center justify-center font-bold">
            {dealData.mediator.netAmount}
          </p>

          <button
            type="button"
            onClick={handleDeleteMediator}
            className="w-[137px] flex items-center justify-center"
          >
            <img
              src={CancelIcon}
              alt="cancel-icon"
              className="w-[20px] h-[20px]"
            />
          </button>
        </div>
      )}
      {/* Description */}
      <div className="w-full flex justify-center">
        <div className="w-[972px] flex flex-col items-center text-sm mb-5 ">
          <p>
            If one party initiates arbitration, the total amount of the deal
            will be placed in a secure escrow account held by the arbitrator.
          </p>
          <p>
            The arbiter will the conduct a hearing and issue a binding decision.
          </p>
        </div>
      </div>

      <div className="flex justify-center">
        {/* Buttons Back && Next */}
        <div className=" flex justify-center">
          <div className="w-[407px] flex justify-around mb-5">
            <button
              className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full"
              type="button"
              onClick={() => setIndicator((prev) => prev - 1)}
            >
              Back
            </button>

            <button
              className={`w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full ${
                mediatorLength === 0 ? "opacity-50" : ""
              }`}
              type="button"
              disabled={mediatorLength === 0}
              onClick={() => {
                setIndicator((prev) => prev + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
        <button
          type="button"
          className="text-2xl font-light underline flex justify-center mt-2 ml-4"
          onClick={() => {
            setIndicator((prev) => prev + 1);
            handleDeleteMediator();
          }}
        >
          Skip
        </button>
      </div>
    </div>
  );
}

// StepFour.propTypes = {
//   dealData: PropTypes.shape({
//     emailPartner: PropTypes.string.isRequired,
//   }).isRequired,
//   handleInputChange: PropTypes.func.isRequired,
//   setIndicator: PropTypes.number.isRequired,
// };

export default StepFour;
