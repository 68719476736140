/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useDisconnect, useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConnectToMetamaskButton from "../components/buttons/ConnectToMetamaskButton";
import notificationIcon from "../assets/icons/notification-icon.png";
import walletIcon from "../assets/icons/wallet.svg";

import {
  CONNECT_WALLET,
  DISCONNECT_WALLET,
  SET_IS_INSTALLED,
} from "../store/features/walletSlice/walletSlice";
import { dealApi, notificationApi } from "../api/api";
import InstallMetamaskModal from "../components/modals/InstallMetamaskModal";

function Navbar() {
  const user = useSelector((state) => state.user);
  const { isConnected, address } = useAccount();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsRT, setNotificationsRT] = useState([]);
  const notificationListRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMetamaskInstalled = useSelector((state) => state.user.isInstalled);
  useEffect(() => {
    if (!window.ethereum) dispatch(SET_IS_INSTALLED());
  }, []);
  useEffect(() => {
    // Listen for account changes
    if (window.ethereum) {
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          const metamaskAddress = accounts[0];
          // Dispatch action to update store
          dispatch(
            CONNECT_WALLET({
              metamaskAddress,
              emailConnected: user.emailConnected,
            })
          );
        } else {
          // No accounts available
          dispatch(DISCONNECT_WALLET());
          // Remove user data from local storage
        }
      };
      const handleChainChanged = async (chain) => {
        if (chain !== "0x89") {
          dispatch(DISCONNECT_WALLET({}));
        } else {
          try {
            const accounts = await window.ethereum.request({
              method: "eth_accounts",
            });
            if (accounts.length > 0) {
              const metamaskAddress = accounts[0];
              dispatch(
                CONNECT_WALLET({
                  metamaskAddress,
                  emailConnected: user.emailConnected,
                })
              );
            } else {
              dispatch(DISCONNECT_WALLET());
            }
          } catch (error) {
            console.log("Error checking Metamask connection:", error);
          }
        }
      };
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);

      return () => {
        // Clean up the event listener
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      };
    }
  }, []);

  useEffect(() => {
    if (address) {
      const url = `https://www.app.d-share.io/sse/${address.toLowerCase()}`;
      const sourceEvent = new EventSource(url);

      sourceEvent.onmessage = function (event) {
        setNotificationsRT((prev) =>
          [
            ...prev,
            {
              message: event.data,
              isRead: false,
              timestamp: new Date().toISOString(),
            },
          ].sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp);
          })
        );
      };

      return () => {
        sourceEvent.close();
      };
    }
  }, [address]);

  // if wallet change , initialize notfications real time
  useEffect(() => {
    setNotificationsRT([]);
  }, [address]);

  // If wallet disconnected, initialize all notifications
  useEffect(() => {
    if (!address) {
      setNotificationsRT([]);
      setNotifications([]);
    }
  }, [address]);

  // Get notifications from database
  useEffect(() => {
    async function fetchNotifications() {
      try {
        const wallet = address;

        const response = await dealApi.getNotificationsByWallet(
          wallet?.toLocaleLowerCase()
        );

        if (response.status === 200) {
          setNotifications(response.data.notifications);
        } else {
          // No notification found for this wallet
          console.log("No notification found for this wallet.");
        }
      } catch (error) {
        console.error("Error when retrieving notifications:", error);
      }
    }

    fetchNotifications();
  }, [address]);

  useEffect(() => {
    const checkMetamaskConnection = async () => {
      if (window.ethereum) {
        const handleChainChanged = (chainId) => {
          if (chainId !== "0x89") {
            dispatch(DISCONNECT_WALLET({}));
          }
        };

        window.ethereum.on("chainChanged", handleChainChanged);

        try {
          const accounts = await window.ethereum.request({
            method: "eth_accounts",
          });

          if (accounts.length > 0) {
            const metamaskAddress = accounts[0];
            dispatch(
              CONNECT_WALLET({
                metamaskAddress,
                emailConnected: user.emailConnected,
              })
            );
          } else {
            dispatch(DISCONNECT_WALLET());
          }
        } catch (error) {
          console.log("Error checking Metamask connection:", error);
        }
      } else {
        dispatch(DISCONNECT_WALLET());
      }
    };

    checkMetamaskConnection();
  }, []);

  const handleNotificationClick = async (notification) => {
    try {
      // Mettez à jour la valeur "isRead" de la notification en la marquant comme lue dans la base de données.
      await notificationApi.notificationIsRead(notification._id);

      navigate(`/deal/${notification.dealId}`);
      // Update notifications
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n._id === notification._id ? { ...n, isRead: true } : n
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleRealTimeNotif = async (index) => {
    setNotificationsRT((prevNotifications) =>
      prevNotifications
        .map((n, i) => (i === index ? { ...n, isRead: true } : n))
        .sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        })
    );
  };

  // Closes list when clicked outside
  const handleClickOutside = (e) => {
    if (
      notificationListRef.current &&
      !notificationListRef.current.contains(e.target)
    ) {
      setOpen(false);
    }
  };

  // Adds a global click event handler
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleans up event handler when component is disassembled
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { disconnect } = useDisconnect({
    onSuccess() {
      console.log("Disconnected");
    },
  });
  // calculate number of unreaded all notifications
  const nbrNotif =
    notifications?.filter((notification) => !notification?.isRead).length +
    notificationsRT?.filter((elem) => !elem?.isRead).length;

  return (
    <div className="w-full h-20 bg-gradient-to-r from-deep-navy to-steel-blue relative flex justify-end items-center px-16">
      <div className="">
        <div className="flex space-x-5 items-center px-6">
          <div className="w-[330px] flex justify-between mr-20">
            {/* New Deal Button */}
            <button
              type="button"
              className="bg-gradient-to-l from-ice-blue to-steel-blue w-[160px] h-[44px] rounded-2xl text-white px-8 py-2"
              onClick={() => navigate("/new-deal")}
            >
              New Deal
            </button>
            {/* My Deals Button */}
            <button
              type="button"
              className="bg-gradient-to-l from-ice-blue to-steel-blue w-[160px] h-[44px] rounded-2xl text-white px-8 py-2 flex justify-between items-center"
              onClick={() => navigate("/dashboard")}
            >
              <span>My Deals</span>
              <img
                src={walletIcon}
                className="w-[20px] h-[20px]"
                alt="wallet"
              />
            </button>
          </div>
          {/* Notifications */}
          <button
            type="button"
            className="relative"
            onClick={() =>
              notifications.concat(notificationsRT)?.length > 0 &&
              setOpen(!open)
            }
          >
            <img src={notificationIcon} alt="notification" />
            {nbrNotif > 0 ? (
              <div className="w-5 h-5 bg-ice-blue rounded-full p-1 text-white text-xs flex items-center justify-center absolute top-[-12px] right-[-8px]">
                {nbrNotif}
              </div>
            ) : null}
          </button>
          {open && (
            <div
              className="absolute w-[500px] right-0 top-[95px] z-10"
              ref={notificationListRef}
            >
              <div className=" shadow-lg rounded-lg overflow-hidden">
                <div className="max-h-[150px] overflow-y-auto">
                  <ul>
                    {notificationsRT.map((elem, index) => (
                      <li
                        key={index}
                        className={`py-2 px-4 mb-2 border-gray-400 rounded-xl text-white  bg-steel-blue hover:bg-gray-600 cursor-pointer ${
                          elem.isRead && "opacity-60 text-white"
                        }`}
                        onClick={() => {
                          handleRealTimeNotif(index);
                          navigate(
                            `/deal/${elem.message.match(/ID(.*?)ID/)[1]}`
                          );
                        }}
                      >
                        <p>
                          {elem.message
                            .replace(/ID(.*?)ID/, "")
                            .replace(/"/g, "")}
                        </p>

                        <span className="text-sm text-white">
                          {elem.timestamp.slice(0, 19).replace("T", " ")}
                        </span>
                      </li>
                    ))}
                    {notifications.map((notification, index) => (
                      <li
                        key={index}
                        className={`py-2 px-4 mb-2 bg-steel-blue text-white border-gray-400 rounded-xl hover:bg-gray-600 cursor-pointer ${
                          notification.isRead && "opacity-60 text-white"
                        }`}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <p>{notification?.message}</p>

                        <span className="text-sm text-white">
                          {notification?.createdAt
                            .slice(0, 19)
                            .replace("T", " ")}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* Wallet address */}
          <ConnectButton />
        </div>
      </div>
      {!isMetamaskInstalled && <InstallMetamaskModal />}
    </div>
  );
}

export default Navbar;
