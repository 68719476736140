/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import deleteIcon from "../../../assets/icons/delete.svg";
import editIcon from "../../../assets/icons/edit.svg";

function StepThree({ dealData, setDealData, setIndicator }) {
  // const { fundingWallet } = dealData;

  // partnerData
  const [partnerData, setPartnerData] = useState({
    email: "",
    sharePercentage: "",
    grossAmount: "",
    feeAmount: "",
    netAmount: "",
  });
  // creatorData
  const [creatorData, setCreatorData] = useState({
    creatorShare: 0,
    creatorGrossAmount: 0,
    creatorFeeAmount: 0,
    creatorNetAmount: 0,
  });

  // ProviderData
  const [providerData, setProviderData] = useState({
    email: "",
    sharePercentage: "",
    grossAmount: "",
    feeAmount: "",
    netAmount: "",
  });

  // Funding Email
  const [fundingEmail, setFundingEmail] = useState("");
  // Edit funding Email (click verification)
  const [editFundingEmail, setEditFundingEmail] = useState(false);
  // Form errors
  const [formErrors, setFormErrors] = useState({});
  const [clientErrors, setClientErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(dealData.partners.length > 0);
  const [editIndex, setEditIndex] = useState(-1);
  const [sharePercentageSum, setSharePercentageSum] = useState(0);
  const [check, setCheck] = useState(false);
  // const [creatorFundingWalletError, setCreatorFundingWalletError] =
  //   useState(false);
  const [partnerFundingWalletError, setPartnerFundingWalletError] =
    useState(false);

  // const [fundingSelected, setFundingSelected] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  // const [confirmClicked, setConfirmClicked] = useState(false);

  // **************    If sharePercentage is filled   *********************
  // ********************************************************************** */
  const sharePercentageFilled = (sharePercentage) => {
    if (!Number.isNaN(sharePercentage)) {
      let netAmount;
      let grossAmount;
      let feeAmount;

      // Calculate netAmount
      if (dealData.fees) {
        netAmount =
          (parseFloat(sharePercentage) / 100) * parseFloat(dealData.dealAmount);
      } else {
        netAmount =
          (parseFloat(sharePercentage) / 100) *
          (parseFloat(dealData.dealAmount) -
            0.005 * parseFloat(dealData.dealAmount));
      }

      // Calculate grossAmount
      if (dealData.fees) {
        grossAmount =
          (parseFloat(sharePercentage) / 100) *
          (parseFloat(dealData.dealAmount) / 0.995);
      } else {
        grossAmount =
          (parseFloat(sharePercentage) / 100) * parseFloat(dealData.dealAmount);
      }

      // Calculate feeAmount
      if (dealData.fees) {
        feeAmount =
          (parseFloat(sharePercentage) / 100) *
          (parseFloat(dealData.dealAmount) / 0.995 -
            parseFloat(dealData.dealAmount));
      } else {
        feeAmount =
          (parseFloat(sharePercentage) / 100) *
          (0.005 * parseFloat(dealData.dealAmount));
      }

      return [
        parseFloat(netAmount.toFixed(4)),
        parseFloat(grossAmount.toFixed(4)),
        parseFloat(feeAmount.toFixed(4)),
      ];
    }
    return "";
  };

  // *******************   If grossAmount is filled  *****************************
  // *****************************************************************************
  const grossAmountFilled = (grossAmount) => {
    if (!Number.isNaN(grossAmount)) {
      let netAmount;
      let sharePercentage;
      let feeAmount;

      // Calculate netAmount
      if (dealData.fees) {
        netAmount =
          (parseFloat(grossAmount) /
            (parseFloat(dealData.dealAmount) / 0.995)) *
          parseFloat(dealData.dealAmount);
      } else {
        netAmount =
          (parseFloat(grossAmount) / parseFloat(dealData.dealAmount)) *
          (parseFloat(dealData.dealAmount) -
            0.005 * parseFloat(dealData.dealAmount));
      }

      // Calculate sharePercentage
      if (dealData.fees) {
        sharePercentage =
          (parseFloat(grossAmount) /
            (parseFloat(dealData.dealAmount) / 0.995).toFixed(4)) *
          100;
      } else {
        sharePercentage =
          (parseFloat(grossAmount) / parseFloat(dealData.dealAmount)) * 100;
      }

      // Calculate feeAmount
      if (dealData.fees) {
        feeAmount =
          (parseFloat(grossAmount) /
            (parseFloat(dealData.dealAmount) / 0.995)) *
          (parseFloat(dealData.dealAmount) / 0.995 -
            parseFloat(dealData.dealAmount));
      } else {
        feeAmount =
          (parseFloat(grossAmount) / parseFloat(dealData.dealAmount)) *
          (parseFloat(dealData.dealAmount) * 0.005);
      }

      return [
        parseFloat(netAmount.toFixed(4)),
        parseFloat(sharePercentage.toFixed(4)),
        parseFloat(feeAmount.toFixed(4)),
      ];
    }
    return "";
  };

  // *******************   If netAmount is filled  *****************************
  // *****************************************************************************
  const netAmountFilled = (netAmount) => {
    if (!Number.isNaN(netAmount)) {
      let grossAmount;
      let sharePercentage;
      let feeAmount;

      // Calculate grossAmount
      if (dealData.fees) {
        grossAmount =
          (parseFloat(netAmount) / parseFloat(dealData.dealAmount)) *
          (parseFloat(dealData.dealAmount) / 0.995);
      } else {
        grossAmount =
          (parseFloat(netAmount) /
            (parseFloat(dealData.dealAmount) -
              0.005 * parseFloat(dealData.dealAmount))) *
          parseFloat(dealData.dealAmount);
      }

      // Calculate sharePercentage
      if (dealData.fees) {
        sharePercentage =
          (parseFloat(netAmount) / parseFloat(dealData.dealAmount)) * 100;
      } else {
        sharePercentage =
          (parseFloat(netAmount) /
            (parseFloat(dealData.dealAmount) -
              0.005 * parseFloat(dealData.dealAmount))) *
          100;
      }

      // Calculate feeAmount
      if (dealData.fees) {
        feeAmount =
          (parseFloat(netAmount) / parseFloat(dealData.dealAmount)) *
            (parseFloat(dealData.dealAmount) / 0.995) -
          parseFloat(netAmount);
      } else {
        feeAmount =
          (parseFloat(netAmount) /
            (parseFloat(dealData.dealAmount) -
              0.005 * parseFloat(dealData.dealAmount))) *
          (parseFloat(dealData.dealAmount) * 0.005);
      }

      return [
        parseFloat(grossAmount.toFixed(4)),
        parseFloat(sharePercentage.toFixed(4)),
        parseFloat(feeAmount.toFixed(4)),
      ];
    }
    return "";
  };

  // Form Error handling
  const validate = (values) => {
    const errors = {};
    // Email validation
    const regex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
    if (!values.email) {
      errors.email = "Enter partner email";
    } else if (!regex.test(values.email)) {
      errors.email = "Enter a valid email address";
    }

    // else if (dealData.creatorEmail === values.email) {
    //   errors.email = "Choose a different email than the creator's email";
    // }
    // sharePercentage validation
    if (
      values.sharePercentage === null ||
      values.sharePercentage === undefined ||
      values.sharePercentage === ""
    ) {
      errors.sharePercentage = "Enter share %";
    } else if (Number.isNaN(Number(values.sharePercentage))) {
      errors.sharePercentage = "Enter a valid number for the share %";
    } else if (Number(values.sharePercentage) > 100) {
      errors.sharePercentage = "The percentage must not exceed 100";
    }

    // grossAmount validation
    if (
      values.grossAmount === null ||
      values.grossAmount === undefined ||
      values.grossAmount === ""
    ) {
      errors.grossAmount = "Enter grossAmount";
    } else if (Number.isNaN(Number(values.grossAmount))) {
      errors.grossAmount = "Enter a valid number for grossAmount";
    } else if (Number(values.grossAmount) > Number(dealData.grossAmount)) {
      errors.grossAmount = `Gross amount entered must not exceed gross amount of the deal: ${dealData.grossAmount} USDT`;
    }

    // netAmount validation
    if (
      values.netAmount === null ||
      values.netAmount === undefined ||
      values.netAmount === ""
    ) {
      errors.netAmount = "Enter netAmount";
    } else if (Number.isNaN(Number(values.netAmount))) {
      errors.netAmount = "Enter a valid number for netAmount";
    } else if (Number(values.netAmount) > Number(dealData.netAmount)) {
      errors.netAmount = `Net amount entered must not exceed net amount of the deal: ${dealData.netAmount} USDT`;
    }

    return errors;
  };

  // Creator Form Error handling
  // Form Error handling
  const validateCreator = (values) => {
    const errors = {};

    // sharePercentage validation
    if (
      values.creatorShare === null ||
      values.creatorShare === undefined ||
      values.creatorShare === ""
    ) {
      errors.creatorShare = "Enter share %";
    } else if (Number.isNaN(Number(values.creatorShare))) {
      errors.creatorShare = "Enter a valid number for the share %";
    } else if (Number(values.creatorShare) > 100) {
      errors.creatorShare = "The percentage must not exceed 100";
    }

    // grossAmount validation
    if (
      values.creatorGrossAmount === null ||
      values.creatorGrossAmount === undefined ||
      values.creatorGrossAmount === ""
    ) {
      errors.creatorGrossAmount = "Enter grossAmount";
    } else if (Number.isNaN(Number(values.creatorGrossAmount))) {
      errors.creatorGrossAmount = "Enter a valid number for grossAmount";
    } else if (
      Number(values.creatorGrossAmount) > Number(dealData.grossAmount)
    ) {
      errors.creatorGrossAmount = `Gross amount entered must not exceed gross amount of the deal`;
    }

    // netAmount validation
    if (
      values.creatorNetAmount === null ||
      values.creatorNetAmount === undefined ||
      values.creatorNetAmount === ""
    ) {
      errors.creatorNetAmount = "Enter netAmount";
    } else if (Number.isNaN(Number(values.creatorNetAmount))) {
      errors.creatorNetAmount = "Enter a valid number for netAmount";
    } else if (Number(values.creatorNetAmount) > Number(dealData.netAmount)) {
      errors.creatorNetAmount = `Net amount entered must not exceed net amount of the deal`;
    }

    return errors;
  };

  // Provider input change
  const handleProviderInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      setProviderData((prev) => ({
        ...prev,
        [name]: value.toLocaleLowerCase(),
      }));
    } else {
      setProviderData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // If sharePercentage is filled
    if (name === "sharePercentage") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "Enter a valid number for the sharePercentage",
        }));
        setProviderData((prev) => ({
          ...prev,
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "",
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
        setProviderData((prev) => {
          const values = sharePercentageFilled(value);
          return {
            ...prev,
            grossAmount: values[1],
            feeAmount: values[2],
            netAmount: values[0],
          };
        });
      }
    }

    // If grossAmount is filled
    if (name === "grossAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "Enter a valid number for the grossAmount",
        }));
        setProviderData((prev) => ({
          ...prev,
          sharePercentage: "",
          feeAmount: "",
          netAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "",
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
        setProviderData((prev) => {
          const values = grossAmountFilled(value);
          return {
            ...prev,
            sharePercentage: values[1],
            feeAmount: values[2],
            netAmount: values[0],
          };
        });
      }
    }

    // If netAmount is filled
    if (name === "netAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          netAmount: "Enter a valid number for the netAmount",
        }));
        setProviderData((prev) => ({
          ...prev,
          sharePercentage: "",
          feeAmount: "",
          grossAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "",
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
        setProviderData((prev) => {
          const values = netAmountFilled(value);
          return {
            ...prev,
            sharePercentage: values[1],
            feeAmount: values[2],
            grossAmount: values[0],
          };
        });
      }
    }
  };

  // Add partner
  const handleAddPartner = () => {
    const errors = validate(providerData);
    if (Object.keys(errors).length === 0) {
      const existingEmails = dealData.partners.map((partner) => partner.email);
      if (existingEmails.includes(providerData.email) && editIndex === -1) {
        setFormErrors({ email: "This email already exists" });
      } else {
        if (editIndex === -1) {
          // Add a new partner
          if (dealData.partners.length < 10) {
            setDealData({
              ...dealData,
              partners: [...dealData.partners, providerData],
            });
            setProviderData({
              email: "",
              sharePercentage: "",
              grossAmount: "",
              feeAmount: "",
              netAmount: "",
            });
            setFormErrors({});
            setIsFormValid(true);
          } else {
            setFormErrors({ maxPartners: "Maximum 10 partners allowed" });
          }
        } else {
          // Update the existing partner
          const updatedPartners = [...dealData.partners];
          updatedPartners[editIndex] = providerData;
          setDealData({
            ...dealData,
            partners: updatedPartners,
          });
          setProviderData({
            email: "",
            sharePercentage: "",
            grossAmount: "",
            feeAmount: "",
            netAmount: "",
          });
          setFormErrors({});
          setIsFormValid(true);
          setEditIndex(-1);
          setIsEditing(false);
        }
      }
    } else {
      setFormErrors(errors);
    }
  };

  // Partner input change
  const handlePartnerInputChange = (e) => {
    const { name, value } = e.target;

    setPartnerData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // If sharePercentage is filled
    if (name === "sharePercentage") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "Enter a valid number for the sharePercentage",
        }));
        setPartnerData((prev) => ({
          ...prev,
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "",
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
        setPartnerData((prev) => {
          const values = sharePercentageFilled(value);
          return {
            ...prev,
            grossAmount: values[1],
            feeAmount: values[2],
            netAmount: values[0],
          };
        });
      }
    }

    // If grossAmount is filled
    if (name === "grossAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "Enter a valid number for the grossAmount",
        }));
        setPartnerData((prev) => ({
          ...prev,
          sharePercentage: "",
          feeAmount: "",
          netAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "",
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
        setPartnerData((prev) => {
          const values = grossAmountFilled(value);
          return {
            ...prev,
            sharePercentage: values[1],
            feeAmount: values[2],
            netAmount: values[0],
          };
        });
      }
    }

    // If netAmount is filled
    if (name === "netAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setFormErrors((prev) => ({
          ...prev,
          netAmount: "Enter a valid number for the netAmount",
        }));
        setPartnerData((prev) => ({
          ...prev,
          sharePercentage: "",
          feeAmount: "",
          grossAmount: "",
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          sharePercentage: "",
          grossAmount: "",
          feeAmount: "",
          netAmount: "",
        }));
        setPartnerData((prev) => {
          const values = netAmountFilled(value);
          return {
            ...prev,
            sharePercentage: values[1],
            feeAmount: values[2],
            grossAmount: values[0],
          };
        });
      }
    }
  };

  // Edit partner
  const editPartner = (index) => {
    const partnerToEdit = dealData.partners[index];
    // setPartnerData(partnerToEdit);
    setProviderData(partnerToEdit);
    setEditIndex(index);
    setIsEditing(true);
  };

  // Delete partner
  const handleDeletePartner = (index) => {
    setDealData((prev) => {
      return {
        ...prev,
        partners: prev.partners.filter((_, i) => i !== index),
      };
    });
  };

  // Funding wallet Data
  const [fundingWalletData] = useState(() => {
    const values = sharePercentageFilled(100);
    return {
      sharePercentage: 100,
      grossAmount: values[1],
      feeAmount: values[2],
      netAmount: values[0],
    };
  });

  // Add Funding wallet informations
  const handleAddFundingWallet = () => {
    const FundingErrors = {};
    // Email validation
    const regex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
    if (!fundingEmail) {
      FundingErrors.email = "Enter client email";
    } else if (!regex.test(fundingEmail)) {
      FundingErrors.email = "Enter a valid email address";
    }
    setClientErrors(FundingErrors);

    if (Object.keys(FundingErrors).length === 0) {
      if (!dealData.fundingWallet) {
        // Add

        // If Funding wallet email is equal to creatorEmail, add creator informations (creator is FW)
        if (
          fundingEmail.toLocaleLowerCase() ===
          dealData.creatorEmail.toLocaleLowerCase()
        ) {
          setDealData((prev) => ({
            ...prev,
            creator: {
              creatorShare: fundingWalletData.sharePercentage,
              creatorGrossAmount: fundingWalletData.grossAmount,
              creatorFeeAmount: fundingWalletData.feeAmount,
              creatorNetAmount: fundingWalletData.netAmount,
            },
            fundingWallet: fundingEmail,
          }));
          setFundingEmail("");
        } else {
          // else add partner informations (partner is FW)

          setDealData((prev) => {
            return {
              ...prev,
              fundingWallet: fundingEmail,
              partners: [
                ...dealData.partners,
                {
                  email: fundingEmail,
                  sharePercentage: fundingWalletData.sharePercentage,
                  grossAmount: fundingWalletData.grossAmount,
                  feeAmount: fundingWalletData.feeAmount,
                  netAmount: fundingWalletData.netAmount,
                },
              ],
            };
          });
          setFundingEmail("");
        }
      } else {
        // Edit
        if (
          fundingEmail.toLocaleLowerCase() ===
          dealData.creatorEmail.toLocaleLowerCase()
        ) {
          // Add creator & delete partner
          setDealData((prev) => ({
            ...prev,
            creator: {
              creatorShare: fundingWalletData.sharePercentage,
              creatorGrossAmount: fundingWalletData.grossAmount,
              creatorFeeAmount: fundingWalletData.feeAmount,
              creatorNetAmount: fundingWalletData.netAmount,
            },
            partners: [
              ...prev.partners.filter(
                (partner) => partner.email !== dealData.fundingWallet
              ),
            ],
            fundingWallet: fundingEmail,
          }));
          setFundingEmail("");
        } else {
          // Add partner & delete creator
          if (
            dealData.creatorEmail.toLocaleLowerCase() ===
            dealData.fundingWallet.toLocaleLowerCase()
          ) {
            setDealData((prev) => {
              // Check if the partner already exists
              const existingPartnerIndex = prev.partners.findIndex(
                (partner) => partner.email === fundingEmail
              );

              if (existingPartnerIndex !== -1) {
                // Partner already exists, update
                const updatedPartners = [...prev.partners];

                updatedPartners[existingPartnerIndex] = {
                  email: fundingEmail,
                  sharePercentage: fundingWalletData.sharePercentage,
                  grossAmount: fundingWalletData.grossAmount,
                  feeAmount: fundingWalletData.feeAmount,
                  netAmount: fundingWalletData.netAmount,
                };

                return {
                  ...prev,
                  fundingWallet: fundingEmail,
                  creator: {},
                  partners: updatedPartners,
                };
              }

              // Else Add partner & delete creator
              return {
                ...prev,
                fundingWallet: fundingEmail,
                creator: {},
                partners: [
                  ...dealData.partners,
                  {
                    email: fundingEmail,
                    sharePercentage: fundingWalletData.sharePercentage,
                    grossAmount: fundingWalletData.grossAmount,
                    feeAmount: fundingWalletData.feeAmount,
                    netAmount: fundingWalletData.netAmount,
                  },
                ],
              };
            });
            setFundingEmail("");
          } else {
            // Update partner
            setDealData((prev) => ({
              ...prev,
              partners: prev.partners.map((partner) => {
                if (partner.email === dealData.fundingWallet) {
                  return { ...partner, email: fundingEmail };
                }
                return partner;
              }),
              fundingWallet: fundingEmail,
            }));
            setFundingEmail("");
          }
        }
        setEditFundingEmail(false);
      }
    }
  };

  // Edit Funding Wallet
  const editFundingWallet = () => {
    setEditFundingEmail(true);
    setFundingEmail(dealData.fundingWallet);
  };

  // Add creator informations
  const handleAddCreator = () => {
    // Check if creator is already funding wallet or not
    if (
      dealData?.fundingWallet &&
      dealData?.fundingWallet === dealData.creatorEmail
    ) {
      setFormErrors({
        email: "This email corresponds to the client's email.",
      });
    } else {
      setDealData({
        ...dealData,
        creator: {
          creatorShare: providerData.sharePercentage,
          creatorGrossAmount: providerData.grossAmount,
          creatorFeeAmount: providerData.feeAmount,
          creatorNetAmount: providerData.netAmount,
        },
      });
      setProviderData({
        email: "",
        sharePercentage: "",
        grossAmount: "",
        feeAmount: "",
        netAmount: "",
      });
    }
  };

  // Delete creator
  const handleDeleteCreator = () => {
    setDealData((prev) => {
      return {
        ...prev,
        creator: {},
      };
    });
  };

  // Edit creator
  const editCreator = () => {
    setProviderData({
      email: dealData.creatorEmail,
      sharePercentage: dealData.creator.creatorShare,
      grossAmount: dealData.creator.creatorGrossAmount,
      feeAmount: dealData.creator.creatorFeeAmount,
      netAmount: dealData.creator.creatorNetAmount,
    });
  };

  // Creator input change
  const handleCreatorInputChange = (e) => {
    const { name, value } = e.target;

    setCreatorData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // If sharePercentage is filled
    if (name === "creatorShare") {
      if (Number.isNaN(Number(value)) || !value) {
        setPartnerData((prev) => ({
          ...prev,
          creatorGrossAmount: "",
          creatorFeeAmount: "",
          creatorNetAmount: "",
        }));
      } else {
        setCreatorData((prev) => {
          const values = sharePercentageFilled(value);
          return {
            ...prev,
            creatorGrossAmount: values[1],
            creatorFeeAmount: values[2],
            creatorNetAmount: values[0],
          };
        });
      }
    }

    // If grossAmount is filled
    if (name === "creatorGrossAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setCreatorData((prev) => ({
          ...prev,
          creatorShare: "",
          creatorFeeAmount: "",
          creatorNetAmount: "",
        }));
      } else {
        setCreatorData((prev) => {
          const values = grossAmountFilled(value);
          return {
            ...prev,
            creatorShare: values[1],
            creatorFeeAmount: values[2],
            creatorNetAmount: values[0],
          };
        });
      }
    }

    // If netAmount is filled
    if (name === "creatorNetAmount") {
      if (Number.isNaN(Number(value)) || !value) {
        setCreatorData((prev) => ({
          ...prev,
          creatorShare: "",
          creatorFeeAmount: "",
          creatorGrossAmount: "",
        }));
      } else {
        setCreatorData((prev) => {
          const values = netAmountFilled(value);
          return {
            ...prev,
            creatorShare: values[1],
            creatorFeeAmount: values[2],
            creatorGrossAmount: values[0],
          };
        });
      }
    }
  };

  // Add  provider
  const handleAddProvider = () => {
    if (
      providerData.email.toLocaleLowerCase() ===
      dealData.creatorEmail.toLocaleLowerCase()
    ) {
      handleAddCreator();
    } else {
      handleAddPartner();
    }
  };

  // Check other partners when creator or partner is funding wallet
  const handleConfirm = () => {
    if (dealData.fundingWallet === dealData.creatorEmail) {
      const hasZeroValues = dealData.partners.some(
        (partner) => partner.sharePercentage === "0"
      );
      if (hasZeroValues) {
        // setCreatorFundingWalletError(true);
        setPartnerFundingWalletError(true);
      } else {
        // setCreatorFundingWalletError(false);
        setPartnerFundingWalletError(false);
      }
    } else {
      const hasZeroValues = dealData.partners.some(
        (partner) =>
          partner.sharePercentage === "0" &&
          partner.email !== dealData.fundingWallet
      );
      if (hasZeroValues) {
        setPartnerFundingWalletError(true);
        //  setCreatorFundingWalletError(false);
      } else {
        setPartnerFundingWalletError(false);
        // setCreatorFundingWalletError(false);
      }
    }
  };

  useEffect(() => {
    // Calculate the sum of sharePercentages
    const partnerPercentages = dealData.partners.map((partner) =>
      Number(partner.sharePercentage)
    );

    const sum = partnerPercentages.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const creatorPercentage = Number(dealData.creator.creatorShare);

    const totalSharePercentageSum = sum + creatorPercentage - 100;

    setSharePercentageSum(totalSharePercentageSum);
    if (dealData.fundingWallet !== "") {
      handleConfirm();
    }
  }, [dealData.partners, dealData.creator, dealData.fundingWallet]);

  return (
    <div className="mx-10 my-7">
      <p className="text-3xl font-bold text-[#ffffff] mb-6">Deal Parameters</p>
      {/* ------------ Table of Funding Wallet ------------ */}
      <div className="border-[1px] border-solid border-[#00e2ff] rounded-lg m-2 p-2">
        <p className="text-3xl font-bold text-[#ffffff] ">From:</p>
        <em>
          Define the amount to be paid from client(s) - put your email if you
          are the client. <strong>The client funds the smart contract.</strong>
        </em>

        {/* Funding Wallet data */}
        {dealData?.fundingWallet && (
          <div className="bg-steel-blue mx-5 w-[1160px]">
            <div
              // key={index}
              className="h-16 flex flex-row justify-around bg-steel-blue mt-2"
            >
              <p className="w-[400px] flex items-center justify-center">
                {dealData?.fundingWallet}
              </p>
              <p className="w-[100px] flex items-center justify-center font-bold">
                {`${fundingWalletData.sharePercentage}%`}
              </p>
              <p className="w-[120px] flex items-center justify-center font-bold">
                {fundingWalletData.grossAmount}
              </p>
              <p className="w-[100px] flex items-center justify-center font-bold">
                {fundingWalletData.feeAmount}
              </p>
              <p className="w-[196px] pr-6 flex items-center justify-around font-bold mr-15">
                {fundingWalletData.netAmount}
              </p>
              <div className="w-[100px] flex">
                {/* {partner.email !== dealData.fundingWallet ? ( */}
                <button
                  type="button"
                  onClick={
                    // editPartner(index)
                    editFundingWallet
                  }
                >
                  <img
                    src={editIcon}
                    alt="edit-icon"
                    className="w-[20px] h-[20px]"
                  />
                </button>
                {/* ) : (
                <div className="w-[20px] h-[20px]" />
              )} */}
              </div>
            </div>
          </div>
        )}
        {/* Add Funding Wallet */}
        <table className="mx-5 mt-4  w-[1160px]">
          <thead>
            <tr className=" text-sm">
              <th>{}</th>
              <th className="pr-6">Share to pay</th>
              <th className="pr-6">Gross amount</th>
              <th className="pr-6">Fee amount</th>
              <th className="pr-6">Net amount to pay</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-16 mb-1 mx-5">
              <td>
                <input
                  className="mr-4 px-4 w-[400px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-steel-blue placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="creatorEmail"
                  placeholder="Client Email address..."
                  value={fundingEmail}
                  // onChange={handleCreatorInputChange}
                  onChange={(e) => {
                    setFundingEmail(e.target.value.toLocaleLowerCase());
                  }}
                  disabled={
                    dealData.fundingWallet && !fundingEmail && !editFundingEmail
                  }
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[120px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] opacity-50 placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="creatorShare"
                  value={fundingWalletData.sharePercentage}
                  // onChange={handleCreatorInputChange}
                  placeholder="0 %"
                  disabled
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[110px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] opacity-50 placeholder:text-slate-400 placeholder:text-xs placeholder:text-center "
                  type="text"
                  name="creatorGrossAmount"
                  value={fundingWalletData.grossAmount}
                  // onChange={handleCreatorInputChange}
                  placeholder="0"
                  disabled
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[100px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] opacity-50 placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="creatorFeeAmount"
                  value={fundingWalletData.feeAmount}
                  // onChange={handleCreatorInputChange}
                  placeholder="0"
                  disabled
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[170px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] opacity-50 placeholder:text-slate-400 placeholder:text-xs placeholder:text-center "
                  type="text"
                  name="creatorNetAmount"
                  value={fundingWalletData.netAmount}
                  // onChange={handleCreatorInputChange}
                  placeholder="0"
                  disabled
                />
              </td>
              <td>
                {/* Add Button */}
                <button
                  className={`w-[137px] h-12 text-[#10131c] text-base font-bold bg-gradient-to-t from-[#ee00e5] to-[#f2f3d0] rounded-full ${
                    ((dealData.fundingWallet && !fundingEmail) ||
                      (!dealData.fundingWallet && !fundingEmail)) &&
                    "opacity-50"
                  }`}
                  type="button"
                  // onClick={handleAddCreator}
                  onClick={handleAddFundingWallet}
                  disabled={
                    (dealData.fundingWallet && !fundingEmail) ||
                    (!dealData.fundingWallet && !fundingEmail)
                  }
                >
                  Confirm
                </button>
              </td>
            </tr>
            <div>
              {clientErrors.email && (
                <p className="text-red-600">{clientErrors.email}</p>
              )}
            </div>
            {/* Funding Wallet Errors validation */}
            <tr>
              <td />
              <td>
                {formErrors.creatorShare && (
                  <p className="text-red-600 w-[100px]">
                    {formErrors.creatorShare}
                  </p>
                )}
              </td>
              <td>
                {" "}
                {formErrors.creatorGrossAmount && (
                  <p className="text-red-600 w-[120px]">
                    {formErrors.creatorGrossAmount}
                  </p>
                )}
              </td>
              <td>&nbsp;</td>
              <td>
                {" "}
                {formErrors.creatorNetAmount && (
                  <p className="text-red-600 w-[196px]">
                    {formErrors.creatorNetAmount}
                  </p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* -------------------- Providers data ---------------- */}
        <p className="text-3xl font-bold text-[#ffffff]">To:</p>
        <p className="mb-2 italic">
          Define the amount to be received by provider(s) - put your email if
          you are the provider.{" "}
        </p>
        {/* ------- creator Data if is not funding wallet ---------- */}
        <div className="bg-steel-blue mx-5 w-[1160px]">
          {Object.keys(dealData.creator).length !== 0 &&
            dealData.creatorEmail.toLocaleLowerCase() !==
              dealData.fundingWallet.toLocaleLowerCase() && (
              <div className="h-16 flex flex-row justify-around bg-steel-blue">
                <p className="w-[400px] flex items-center justify-center">
                  {dealData.creatorEmail}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {`${dealData.creator.creatorShare}%`}
                </p>
                <p className="w-[120px] flex items-center justify-center font-bold">
                  {dealData.creator.creatorGrossAmount}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {dealData.creator.creatorFeeAmount}
                </p>
                <div className="w-[196px] flex items-center justify-around font-bold">
                  <p>{dealData.creator.creatorNetAmount}</p>
                  <button type="button" onClick={() => handleDeleteCreator()}>
                    <img
                      src={deleteIcon}
                      alt="delete-icon"
                      className="w-[20px] h-[20px]"
                    />
                  </button>
                </div>
                <div className="w-[100px] flex">
                  {dealData.creatorEmail.toLocaleLowerCase() !==
                  dealData.fundingWallet.toLocaleLowerCase() ? (
                    <button type="button" onClick={() => editCreator()}>
                      <img
                        src={editIcon}
                        alt="edit-icon"
                        className="w-[20px] h-[20px]"
                      />
                    </button>
                  ) : (
                    <div className="w-[20px] h-[20px]" />
                  )}
                </div>
              </div>
            )}
        </div>
        {/* --------------- Partners data ---------------------- */}
        <div className="bg-steel-blue mx-5 w-[1160px]">
          {dealData.partners
            .map((partner, index) => ({ ...partner, index })) // Ajouter l'index original à chaque partenaire
            .filter((partner) => partner.email !== dealData.fundingWallet)
            .map((partner) => (
              <div
                key={partner.index}
                className={`h-16 flex flex-row justify-around bg-steel-blue  ${
                  partner.email === dealData.fundingWallet
                    ? "border-2 border-ice-blue"
                    : ""
                }`}
              >
                <p className="w-[400px] flex items-center justify-center">
                  {partner.email}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {`${partner.sharePercentage}%`}
                </p>
                <p className="w-[120px] flex items-center justify-center font-bold">
                  {partner.grossAmount}
                </p>
                <p className="w-[100px] flex items-center justify-center font-bold">
                  {partner.feeAmount}
                </p>
                <div className="w-[196px] flex items-center justify-around font-bold">
                  <p>{partner.netAmount}</p>
                  <button
                    type="button"
                    onClick={() => handleDeletePartner(partner.index)}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete-icon"
                      className="w-[20px] h-[20px]"
                    />
                  </button>
                </div>
                <div className="w-[100px] flex">
                  {partner.email !== dealData.fundingWallet ? (
                    <button
                      type="button"
                      onClick={() => editPartner(partner.index)}
                    >
                      <img
                        src={editIcon}
                        alt="edit-icon"
                        className="w-[20px] h-[20px]"
                      />
                    </button>
                  ) : (
                    <div className="w-[20px] h-[20px]" />
                  )}
                </div>
              </div>
            ))}
        </div>
        {/* ------------ Table of adding partners ------- */}
        <table className="mx-5 mt-4 w-[1160px]">
          <thead>
            <tr className="text-left text-sm">
              <th>{}</th>
              {formErrors.maxPartners && (
                <p className="text-red-600 mb-4 ml-5">
                  {formErrors.maxPartners}
                </p>
              )}
              <th className="pl-2">Share to receive</th>
              <th className="pl-2">Gross amount</th>
              <th className="pl-2">Fee amount</th>
              <th className="pl-3">Net amount to receive</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-16 mb-1 mx-5">
              <td>
                <input
                  className="mr-4 px-4 w-[400px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="email"
                  // value={partnerData.email}
                  // onChange={handlePartnerInputChange}
                  value={providerData.email}
                  onChange={handleProviderInputChange}
                  placeholder="Provider Email address..."
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[120px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="sharePercentage"
                  // value={partnerData.sharePercentage}
                  // onChange={handlePartnerInputChange}
                  value={providerData.sharePercentage}
                  onChange={handleProviderInputChange}
                  placeholder="Share %"
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[110px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="grossAmount"
                  // value={partnerData.grossAmount}
                  // onChange={handlePartnerInputChange}
                  value={providerData.grossAmount}
                  onChange={handleProviderInputChange}
                  placeholder="Gross Amount"
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[100px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] opacity-50 placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="feeAmount"
                  // value={partnerData.feeAmount}
                  // onChange={handlePartnerInputChange}
                  value={providerData.feeAmount}
                  onChange={handleProviderInputChange}
                  placeholder="Fee Amount"
                  disabled
                />
              </td>
              <td>
                <input
                  className="mr-4 px-4 w-[170px] h-12 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                  type="text"
                  name="netAmount"
                  // value={partnerData.netAmount}
                  // onChange={handlePartnerInputChange}
                  value={providerData.netAmount}
                  onChange={handleProviderInputChange}
                  placeholder="Net Amount"
                />
              </td>
              <td>
                {/* Add Button */}
                <button
                  className={`w-[137px] h-12 text-[#10131c] text-base font-bold bg-gradient-to-t from-[#ee00e5] to-[#f2f3d0] rounded-full ${
                    Object.values(providerData).some((value) => value === "") &&
                    "opacity-50"
                  }`}
                  type="button"
                  disabled={Object.values(providerData).some(
                    (value) => value === ""
                  )}
                  onClick={handleAddProvider}
                >
                  {isEditing ? "Save" : "Add"}
                </button>
              </td>
            </tr>
            <tr>
              <td>
                {formErrors.email && (
                  <p className="text-red-600">{formErrors.email}</p>
                )}
              </td>
              <td className="">
                {formErrors.sharePercentage && (
                  <p className="text-red-600 w-[100px]">
                    {formErrors.sharePercentage}
                  </p>
                )}
              </td>
              <td>
                {" "}
                {formErrors.grossAmount && (
                  <p className="text-red-600 w-[120px]">
                    {formErrors.grossAmount}
                  </p>
                )}
              </td>
              <td>&nbsp;</td>
              <td>
                {" "}
                {formErrors.netAmount && (
                  <p className="text-red-600 w-[196px]">
                    {formErrors.netAmount}
                  </p>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {/* ------------ Handling errors --------------- */}
        {sharePercentageSum !== 100 &&
          check &&
          Object.keys(dealData?.creator).length !== 0 &&
          dealData?.partners.length !== 0 && (
            <p className="text-red-600 ml-5 mt-5">
              Please ensure that the sum of providers share percentages equals
              100.
            </p>
          )}

        {/* {creatorFundingWalletError && (
          <p className="text-red-600 ml-5 mt-5">
            You can&apos;t have a creator as funding with partners who have zero
            values.
          </p>
        )} */}

        {/* {partnerFundingWalletError && check && (
          <p className="text-red-600 ml-5 mt-5">
            You can&apos;t have a partner as funding with partners who have zero
            values.
          </p>
        )} */}

        {Object.keys(dealData?.creator).length === 0 && check && (
          <p className="text-red-600 ml-5 mt-5">
            Please include the creator&apos;s email address.
          </p>
        )}

        {dealData?.partners.length === 0 && check && (
          <p className="text-red-600 ml-5 mt-5">
            Please include at least one partner.
          </p>
        )}

        {/* ----------------- Description ----------------------- */}
        <div className="w-full flex justify-center">
          <div className="w-[972px] flex flex-col items-center text-sm my-5 ">
            <p>
              Determine the distribution allocation for all partners and assign
              a percentage to each wallet upon mutual agreement.
            </p>
            <p className="font-bold">
              Ensure the sum of all partner percentages from all milestones
              equals 100% and does not exeed the total deal amount.
            </p>
          </div>
        </div>
      </div>

      {/* ---------------- Buttons Back && Next ------------------- */}
      <div className="w-full flex justify-center">
        <div className="w-[407px] flex justify-around mt-4">
          <button
            className="w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full"
            type="button"
            onClick={() => setIndicator((prev) => prev - 1)}
          >
            Back
          </button>
          <button
            className={`w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full ${
              (dealData.partners.length === 0 ||
                sharePercentageSum !== 100 ||
                dealData.fundingWallet === "") &&
              // ||
              // creatorFundingWalletError ||
              // partnerFundingWalletError
              "opacity-50"
            }`}
            type="button"
            onClick={() => {
              const errorsCreator = validateCreator(creatorData);
              if (Object.keys(errorsCreator).length !== 0) {
                setFormErrors((prev) => ({ ...prev, ...errorsCreator }));
                return;
              }
              setCheck(true);
              handleConfirm();
              // setFundingSelected(true);

              if (
                sharePercentageSum === 100 &&
                dealData.fundingWallet !== ""
                // &&
                // !creatorFundingWalletError &&
                // !partnerFundingWalletError
              ) {
                if (dealData.partners.length > 0) {
                  // handleAddCreator();
                  setIndicator((prev) => prev + 1);
                }
                // else {
                //   handleAddPartner();
                // }
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

// StepThree.propTypes = {
//   dealData: PropTypes.shape({
//     emailPartner: PropTypes.string.isRequired,
//   }).isRequired,
//   handleInputChange: PropTypes.func.isRequired,
//   setIndicator: PropTypes.number.isRequired,
// };

export default StepThree;
