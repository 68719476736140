/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { feeApi } from "../../../api/api";

function StepOne({ dealData, setDealData, handleInputChange, setIndicator }) {
  // Form errors
  const [formErrors, setFormErrors] = useState({});
  const [previousFees, setPreviousFees] = useState(dealData.fees);
  const [previousDealAmount, setPreviousDealAmount] = useState(
    dealData.dealAmount
  );
  const [previousCreatorEmail, setPreviousCreatorEmail] = useState(
    dealData.creatorEmail
  );
  const [fee, setFee] = useState("");

  const handleClick = () => {
    setDealData((prev) => ({
      ...prev,
      fees: !prev.fees,
    }));
  };

  useEffect(() => {
    //  Get Fee from database
    const getFeeValue = async () => {
      try {
        // Fetch fee value using the feeApi
        const dealResponse = await feeApi.getFeeValue();
        // Return the fee value
        setFee(dealResponse.data.value);
      } catch (error) {
        console.error("Error fetching fee value:", error);
        return null;
      }
    };

    getFeeValue();
  }, []);
  // Calculate the values according to the input in the dealAmount field and ON/OFF

  const protocolFee = dealData.fees
    ? parseFloat(
        (
          parseFloat(dealData.dealAmount) / (1 - fee) -
          parseFloat(dealData.dealAmount)
        ).toFixed(4)
      )
    : parseFloat((fee * parseFloat(dealData.dealAmount)).toFixed(4));

  const grossAmount = dealData.fees
    ? String((parseFloat(dealData.dealAmount) + protocolFee).toFixed(4))
    : String(parseFloat(dealData.dealAmount).toFixed(4));

  const netAmount = dealData.fees
    ? parseFloat(dealData.dealAmount).toFixed(4)
    : (parseFloat(dealData.dealAmount) - protocolFee).toFixed(4);

  // Form Error handling
  const validate = (values) => {
    const errors = {};
    // Email validation
    const regex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
    if (!values.creatorEmail) {
      errors.creatorEmail = "Enter your email";
    } else if (!regex.test(values.creatorEmail)) {
      errors.creatorEmail = "Enter a valid email address";
    }
    // Deal name validation
    if (!values.dealName) {
      errors.dealName = "Enter a deal name";
    } else {
      const regex = /^[a-zA-Z0-9\s]*$/; // This regex allows letters, numbers, and spaces
      if (!regex.test(values.dealName)) {
        errors.dealName = "Deal name cannot contain special characters";
      }
    }

    // Deal name length validation
    if (values.dealName.length > 25) {
      errors.dealName = "Choose a deal name that does not exceed 25 characters";
    }

    // Deal amount validation
    if (!values.dealAmount) {
      errors.dealAmount = "Enter a deal amount";
    } else if (Number.isNaN(Number(values.dealAmount))) {
      errors.dealAmount = "Enter a valid number for the deal amount";
    } else if (values.dealAmount < 5) {
      errors.dealAmount = "Deal amount must be at least $5";
    }

    // Blockchain validation
    if (!values.blockchain) {
      errors.blockchain = "Choice a network";
    }
    // Currency validation
    if (!values.currency) {
      errors.currency = "Choice a currency";
    }

    return errors;
  };

  // Initialize creator & partners & mediator if fees was changed OR if dealAmount was changed OR if creatorEmail was changed
  useEffect(() => {
    if (dealData.fees !== previousFees) {
      setDealData((prev) => ({
        ...prev,
        partners: [],
        mediator: {},
        creator: {},
        fundingWallet: "",
      }));
      // Update previous fees value
      setPreviousFees(dealData.fees);
    } else if (dealData.dealAmount !== previousDealAmount) {
      setDealData((prev) => ({
        ...prev,
        partners: [],
        mediator: {},
        creator: {},
        fundingWallet: "",
      }));
      // Update previous dealAmount value
      setPreviousDealAmount(dealData.dealAmount);
    }
  }, [dealData.fees, dealData.dealAmount]);

  return (
    <>
      <div className="mx-10 my-7">
        <p className="text-3xl font-bold text-[#ffffff] mb-6">
          Deal Information
        </p>
        {/* Your creatorEmail */}
        <div className="flex flex-col ">
          <label
            htmlFor="creatorEmail"
            className="text-base font-bold text-[#f3f8fe]"
          >
            Your Email Address
            <input
              className="px-4 w-full h-10 mt-3 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
              type="creatorEmail"
              name="creatorEmail"
              id="creatorEmail"
              placeholder="Email Address..."
              value={dealData.creatorEmail}
              onChange={handleInputChange}
            />
            {formErrors.creatorEmail && (
              <p className="text-red-600 text-sm font-normal">
                {formErrors.creatorEmail}
              </p>
            )}
          </label>
        </div>
        <br />
        {/* Deal Name */}
        <div className="flex flex-col">
          <label
            htmlFor="dealName"
            className="text-base font-bold text-[#f3f8fe]"
          >
            Set Deal Name
            <input
              className="px-4 w-full h-10 mt-3 text-center border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
              type="text"
              name="dealName"
              id="dealName"
              placeholder="Name your deal..."
              value={dealData.dealName}
              onChange={handleInputChange}
            />
            {formErrors.dealName && (
              <p className="text-red-600 text-sm font-normal">
                {formErrors.dealName}
              </p>
            )}
          </label>
        </div>
        <br />
        {/* Deal Amount */}
        <div className="flex flex-col">
          <span
            htmlFor="dealAmount"
            className="text-base font-bold text-[#f3f8fe]"
          >
            Set Deal Total Amount
          </span>
          <div className="flex justify-between flex-row-reverse">
            {/* Currency */}
            <div className="flex flex-col">
              <select
                name="currency"
                id="currency"
                value={dealData.currency}
                onChange={handleInputChange}
                className="px-4 w-30 h-10 mt-3 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] text-slate-400 text-xs text-center"
              >
                <option value="" disabled hidden>
                  Currency
                </option>
                <option value="USDT">USDT</option>
              </select>
              {formErrors.currency && (
                <p className="text-red-600 text-sm">{formErrors.currency}</p>
              )}
            </div>
            {/* Blockchain type */}
            <div className="flex flex-col">
              <select
                name="blockchain"
                id="blockchain"
                className="px-4 w-30 h-10 mt-3 border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] text-slate-400 text-xs text-center"
                value={dealData.blockchain}
                onChange={handleInputChange}
              >
                <option value="" disabled hidden>
                  Network
                </option>
                <option value="polygon">Polygon</option>
              </select>
              {formErrors.blockchain && (
                <p className="text-red-600 text-sm">{formErrors.blockchain}</p>
              )}
            </div>
            {/* Deal Amount */}
            <div className="flex flex-col mt-3 w-1/3">
              <input
                className="h-10 text-center font-bold border-[1px] border-solid border-[#00e2ff] rounded-lg bg-[#17202c] placeholder:text-slate-400 placeholder:text-xs placeholder:text-center"
                type="text"
                name="dealAmount"
                id="dealAmount"
                placeholder="Amount..."
                value={dealData.dealAmount}
                onChange={handleInputChange}
              />
              {formErrors.dealAmount && (
                <p className="text-red-600 text-sm">{formErrors.dealAmount}</p>
              )}
            </div>
          </div>
        </div>
        <br />

        {/* Include protocol fee */}

        <div className="">
          <div className="flex flex-col">
            <span
              htmlFor="dealAmount"
              className="text-base font-bold text-[#f3f8fe] mb-4"
            >
              Choose Who Will Pay The Protocol Fees (0,5%)
            </span>
            {/* Paid By Client / Paid By Providers(s) */}
            <button
              type="button"
              className="relative w-[360px] h-8 rounded-full text-center font-bold"
              onClick={handleClick}
            >
              <div
                className={`absolute left-0 top-0 w-1/2 h-full rounded-l-full ${
                  dealData.fees
                    ? "bg-ice-blue text-shadow-blue"
                    : "bg-shadow-blue"
                } transition flex items-center justify-center`}
              >
                Paid By Client
              </div>
              <div
                className={`absolute right-0 top-0 w-1/2 h-full rounded-r-full ${
                  dealData.fees
                    ? "bg-shadow-blue"
                    : "bg-ice-blue text-shadow-blue"
                } transition flex items-center justify-center`}
              >
                Paid By Provider(s)
              </div>
            </button>
          </div>
          {/* Deal Amount Fee incl */}
          <div className="mt-3">
            <div className="text-sm text-[#f3f8fe] flex">
              <p className="mr-2">Deal Amount Fee incl.:</p>
              <p className="font-bold">
                {dealData.dealAmount &&
                  (dealData.fees || !dealData.fees) &&
                  grossAmount &&
                  `${grossAmount} USDT`}
              </p>
            </div>
            {/*  Paid by Client */}
            <div className="text-sm text-[#f3f8fe] flex">
              <p className="mr-2"> Paid by Client:</p>
              <p className="font-bold">
                {dealData.dealAmount &&
                  (dealData.fees || !dealData.fees) &&
                  (dealData.fees ? `${protocolFee} USDT` : "0 USDT")}
              </p>
            </div>
            {/*  Paid by Provider(s) */}
            <div className="text-sm text-[#f3f8fe] flex">
              <p className="mr-2">Paid by Provider(s):</p>
              <p className="font-bold">
                {dealData.dealAmount &&
                  (dealData.fees || !dealData.fees) &&
                  (dealData.fees ? "0 USDT" : `${protocolFee} USDT`)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Button Next */}
      <div className="flex justify-end mb-5 mr-8">
        <button
          className={`w-[179px] h-[50px] text-[#f3f8fe] text-base font-bold bg-gradient-to-t from-ice-blue to-steel-blue ... rounded-full ${
            Object.keys(validate(dealData)).length !== 0 && "opacity-50"
          }`}
          type="button"
          onClick={() => {
            const errors = validate(dealData);

            if (Object.keys(errors).length === 0) {
              setDealData((prev) => ({
                ...prev,
                grossAmount,
                netAmount,
                fees_amount: protocolFee,
              }));
              setIndicator((prev) => prev + 1);
            } else {
              setFormErrors(errors);
            }
            // Initialize creator & partners & mediator if creatorEmail was changed
            if (
              dealData.creatorEmail.toLowerCase() !==
              previousCreatorEmail.toLowerCase()
            ) {
              setDealData((prev) => ({
                ...prev,
                partners: [],
                mediator: {},
                creator: {},
                fundingWallet: "",
              }));
              // Update previous creatorEmail value
              setPreviousCreatorEmail(dealData.creatorEmail);
            }
          }}
        >
          Next
        </button>
      </div>
    </>
  );
}

StepOne.propTypes = {
  dealData: PropTypes.shape({
    creatorEmail: PropTypes.string.isRequired,
    dealName: PropTypes.string.isRequired,
    dealAmount: PropTypes.string.isRequired,
    blockchain: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    fees: PropTypes.bool.isRequired,
    grossAmount: PropTypes.string.isRequired,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  setIndicator: PropTypes.func.isRequired,
};

export default StepOne;
