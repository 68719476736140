import React from "react";

function Sourcing() {
  return (
    <div className="flex justify-center ml-80 mr-80 mb-16">
      <div className="text-white flex flex-col items-stretch bg-gradient-to-t from-deep-navy to-steel-blue filter drop-shadow-md border-[5px] border-solid border-[#00e2ff] rounded-lg">
        {/* Title */}
        <div className="flex flex-row items-center justify-between m-4">
          <legend className="text-2xl font-bold text-[#ffffff]">
            Harnessing Data for Smart Payment Triggers
          </legend>
        </div>

        <hr className="border-[1px] border-solid border-[#00e2ff] opacity-50" />

        {/* Text Content */}
        <div className="m-4">
          <p>
            Welcome to the evolution of automated and data-driven transactions!
            At D-Share, our core module is advancing to integrate sourcing data.
            This means unlocking a new era where payments are automatically
            triggered based on real and verifiable data metrics. Imagine
            initiating payments based on metrics such as video views or through
            data sourced from reliable oracles.
          </p>

          <p>
            If you are interested in this feature, drop us an email with your
            ideas and needs:{" "}
            <a href="mailto:deal@d-share.io" className="text-[#00e2ff]">
              deal@d-share.io
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sourcing;
