import { configureStore } from "@reduxjs/toolkit";
import sampleReducer from "./features/sampleSlice/sampleSlice";
import walletReducer from "./features/walletSlice/walletSlice";
import mediatorReducer from "./features/mediatorSlice/mediatorSlice";

const store = configureStore({
  reducer: {
    sample: sampleReducer,
    user: walletReducer,
    mediator: mediatorReducer,
  },
});

export default store;
