/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import NewDealForm from "../components/deal/form/NewDealForm";

function NewDeal() {
  const { isConnected } = useAccount();
  const [network, setNetwork] = useState();
  useEffect(() => {
    async function fetchNetwork() {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const networkId = window.ethereum.networkVersion;
        setNetwork(networkId);
      } catch (error) {
        console.error("Error fetching network:", error);
      }
    }
    try {
      fetchNetwork();

      const handleChainChanged = (chain) => {
        setNetwork(chain);
      };

      window.ethereum.on("chainChanged", handleChainChanged);

      return () => {
        window.ethereum.off("chainChanged", handleChainChanged);
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="flex flex-col w-full">
      {!isConnected || (network !== "137" && network !== "0x89") ? (
        <div className="flex justify-center items-center ">
          <div>
            <ConnectButton />
          </div>
        </div>
      ) : (
        <NewDealForm />
      )}
    </div>
  );
}

export default NewDeal;
