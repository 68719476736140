import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import blockchainLogo from "../assets/images/blockchain-logo.png";
import dashboardIcon from "../assets/images/dashboardIcon.png";
import sourcingIcon from "../assets/images/sourcingIcon.png";
import newDealIcon from "../assets/images/newDealIcon.png";
import leftArrow from "../assets/images/leftArrow.svg";
import rightArrow from "../assets/images/rightArrow.svg";
// import sideBarLogo from "../assets/images/sidebar-logo.png";
import sideBarLogo from "../assets/images/logo-dshare-vecto.png";

function LeftSideBar() {
  const [isExpanded, setIsExpanded] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [selectedItem, setSelectedItem] = useState(""); // State to track the selected item
  const currentPage = window.location.pathname.split("/")[1];

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(window.innerWidth >= 920);
    };

    // Initial check and event listener setup
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`min-h-screen bg-shadow-blue relative    top-0 left-0 z-10 transition-all duration-300 ${
        isExpanded ? "w-44" : "w-20"
      }`}
    >
      <div className="mt-10 mb-16 flex justify-center">
        <img src={sideBarLogo} alt="logo" />
      </div>
      <button
        type="button"
        className="w-full flex justify-end mb-8 ml-4"
        onClick={toggleSidebar}
      >
        {isExpanded ? (
          <img src={leftArrow} alt="left arrow" />
        ) : (
          <img src={rightArrow} alt="right arrow" />
        )}
      </button>
      <ul className="text-white space-y-4 font-bold">
        <Link to="/dashboard" className="flex space-x-4">
          <button
            type="button"
            className={`flex flex-row space-x-4 w-full px-4 py-2 hover:bg-violet-950 cursor-pointer ${
              isExpanded ? "" : "justify-center"
            } ${
              currentPage === "dashboard"
                ? "bg-violet-950 border-l-4 border-l-ice-blue"
                : ""
            }`}
            onClick={() => handleItemClick("dashboard")}
          >
            <img src={dashboardIcon} alt="dashboard" />
            {isExpanded && <p>Dashboard</p>}
          </button>
        </Link>
        <Link to="/new-deal" className="flex space-x-4">
          <button
            type="button"
            className={`flex flex-row space-x-4 w-full px-4 py-2 hover:bg-violet-950 cursor-pointer ${
              isExpanded ? "" : "justify-center"
            } ${
              currentPage === "new-deal"
                ? "bg-violet-950 border-l-4 border-l-ice-blue"
                : ""
            }`}
            onClick={() => handleItemClick("newDeal")}
          >
            <img src={newDealIcon} alt="newDeal" />
            {isExpanded && <p>New Deal</p>}
          </button>
        </Link>
        <Link to="/sourcing" className="flex space-x-4">
          <button
            type="button"
            className={`flex flex-row space-x-4 w-full px-4 py-2 hover:bg-violet-950 cursor-pointer ${
              isExpanded ? "" : "justify-center"
            } ${
              currentPage === "sourcing"
                ? "bg-violet-950 border-l-4 border-l-ice-blue"
                : ""
            }`}
            onClick={() => handleItemClick("sourcing")}
          >
            <img src={sourcingIcon} alt="sourcing" />
            {isExpanded && (
              <div className="flex items-center">
                <p>Sourcing</p>{" "}
                <p className="text-xs font-light ml-1 mt-1">[soon]</p>{" "}
              </div>
            )}
          </button>
        </Link>
      </ul>
      <div className={` bottom-16  absolute ${isExpanded ? "" : "hidden"}`}>
        <img src={blockchainLogo} alt="logo" />
      </div>
    </div>
  );
}

export default LeftSideBar;
