import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DealsListTable from "../components/deal/table/DealsListTable";
import ConnectToMetamaskButton from "../components/buttons/ConnectToMetamaskButton";
import BlueIceButton from "../components/buttons/BlueIceButton";

function Dashboard() {
  const navigate = useNavigate();
  const isConnected = useSelector((state) => state.user.isConnected);
  return (
    <div className="flex flex-col items-center justify-center ">
      <DealsListTable />

      <div className="w-1/4 m-28">
        {!isConnected ? (
          <ConnectToMetamaskButton />
        ) : (
          <BlueIceButton
            text="Create New Deal"
            onClick={() => navigate("/new-deal")}
          />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
