import React from "react";
import { Outlet } from "react-router-dom";
import LeftSideBar from "../coreUI/LeftSideBar";
import Navbar from "../coreUI/Navbar";
// import DshareLogo from "../assets/images/centered-logo.png";

function Layout() {
  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-steel-blue via-deep-navy to-eclipse-blue">
      <div className="flex">
        <LeftSideBar />
        <div className="flex flex-col flex-1">
          <Navbar />
          <div className="  flex flex-col items-center mb-10 mt-10">
            <div>
              {/* <img src={DshareLogo} alt="DshareLogo" className="w-40" /> */}
            </div>
          </div>
          <div className="flex justify-center items-center h-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
