import { createSlice } from "@reduxjs/toolkit";

const mediatorSlice = createSlice({
  name: "mediator",
  initialState: false,
  reducers: {
    setMediatorStatus: (state, action) => {
      return action.payload;
    },
    clearMediator: () => {
      return false;
    },
  },
});

export const { setMediatorStatus, clearMediator } = mediatorSlice.actions;
export default mediatorSlice.reducer;
